import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Table, Form, Button } from 'react-bootstrap'
import { Oval } from "react-loader-spinner"
import { FaPeopleGroup, FaPlus } from "react-icons/fa6";
import { MdExitToApp } from "react-icons/md";
import { BASE_URL } from '../../utils/constants';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import _ from "underscore"

import "./Patients.scss"

const Patients = ({ user, logUserOut, dateRange, setDateRange }) => {
  const [loading, setLoading] = useState(true)
  const [searchValue, setSearchValue] = useState("")
  const [showEmail, setShowEmail] = useState(false)
  const [patients, setPatients] = useState([])
  const [filteredPatients, setFilteredPatients] = useState(null)
  const [startDate, endDate] = dateRange;
  const filterOptions = [
    { value: 'procedureDateAsc', label: 'Procedure date asc' },
    { value: 'procedureDateDesc', label: 'Procedure date desc' },
    { value: 'patientIdAsc', label: 'Patient ID asc' },
    { value: 'patientIdDesc', label: 'Patient ID desc' },
    { value: 'emailAsc', label: 'Email asc' },
    { value: 'emailDesc', label: 'Email desc' },
    { value: 'studyAsc', label: 'Study asc' },
    { value: 'studyDesc', label: 'Study desc' },
  ]
  const navigate = useNavigate();

  useEffect(() => {
    const getPatientsByEventDate = async (dateRange) => {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
      
      const body = {
        startDate: dateRange[0],
        endDate: dateRange[1]
      }
      try {
        let response = null
        if(startDate !== null && endDate !== null) {
          setLoading(true)
          const response = await axios.post(`${BASE_URL}/api/v1/patient/by-event-date`, body, config)
          setPatients(response.data.patients)
          setLoading(false)
        }
        if(startDate === null && endDate === null) {
          setLoading(true)
          response = await axios.get(`${BASE_URL}/api/v1/patient/all`, config)
          setPatients(response.data.patients)
          setLoading(false)
        }
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    getPatientsByEventDate(dateRange)
  }, [dateRange])

  
  useEffect(() => {
    if(searchValue !== "") {
      const filtered = patients.filter(patient => { 
        return patient.custom_patient_id.toLowerCase().includes(searchValue) || patient.email.toLowerCase().includes(searchValue) || patient.study.toLowerCase().includes(searchValue); 
      });
      setFilteredPatients(filtered)
    }
    else {
      setFilteredPatients(null)
    }
  }, [searchValue])

  const handleSelectInput = (orderOption) => {
    let orderedPatients = []
    switch(orderOption.value) {
      case "procedureDateAsc":
        orderedPatients = _.sortBy(patients, "event_date")
      break;
      case "procedureDateDesc":
        orderedPatients = _.sortBy(patients, "event_date").reverse()
      break;
      case "patientIdAsc":
        orderedPatients = _.sortBy(patients, "custom_patient_id")
      break;
      case "patientIdDesc":
        orderedPatients = _.sortBy(patients, "custom_patient_id").reverse()
      break;
      case "emailAsc":
        orderedPatients = _.sortBy(patients, "email")
      break;
      case "emailDesc":
        orderedPatients = _.sortBy(patients, "email").reverse()
      break;
      case "studyAsc":
        orderedPatients = _.sortBy(patients, "study")
      break;
      case "studyDesc":
        orderedPatients = _.sortBy(patients, "study").reverse()
      break;
      default:
        orderedPatients = _.sortBy(patients, "custom_patient_id")
    }
    setPatients(orderedPatients)
  }

  const handleDayClick = (day) => {
    let date = new Date()
    switch(day) {
      case "yesterday":
        date.setDate(date.getDate() - 1);
        setDateRange([date, date])
      break;
      case "today":
        setDateRange([date, date])
      break;
      case "tomorrow":
        date.setDate(date.getDate() + 1);
        setDateRange([date, date])
      break;
      default: return
    }
  }
  
  return (
    <div className="patients">
      {loading ? (<Oval
          height={80}
          width={80}
          color="#4caeff"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#4caeff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        ) : (
        <>
          <div className="patients-header">
            <h2 className="logo"><FaPeopleGroup /> Patients</h2>      
            <Button variant="success" onClick={() => navigate("/patients/new")}>
              <FaPlus /> Enrol Patient
            </Button>
          </div>
          
          <div className="filters">
            <Form.Group className="form-group search">
              <Form.Control
                type="text"
                placeholder="Search"
                // value={searchValue}
                onChange={(event) => setSearchValue(event.target.value.toLowerCase())}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="form-group">
              <DatePicker className='form-control procedure-date-picker'
                selected={startDate}
                monthsShown={2}
                onChange={(update) => {setDateRange(update)}}
                startDate={startDate}
                endDate={endDate}
                placeholderText="Procedure Date"
                selectsRange={true}
                isClearable={true}
                dateFormat="dd/MM/yyyy"
                >  
                <div className='dp-footer'>
                <Button variant="secondary" onClick={() => handleDayClick("yesterday")}>
                  Yesterday
                </Button>
                <Button variant="secondary" onClick={() => handleDayClick("today")}>
                  Today
                </Button>
                <Button variant="secondary" onClick={() => handleDayClick("tomorrow")}>
                  Tomorrow
                </Button>
                </div>
              </DatePicker>
            </Form.Group>
          </div>
          
          <div className='sort'>
            <Select      
              isMulti={false}
              name="filter"
              defaultValue={filterOptions[2]}
              options={filterOptions}
              className="basic-multi-select"
              placeholder="Order By"
              classNamePrefix="select"
              onChange={handleSelectInput}
            />
          </div>   
          <div className='patients-table'>
            <Table striped bordered={false} hover>
              <thead>
                <tr>
                  <th className='custom-id'>Patient ID</th>
                  <th className='email'>Email (<span className='show-hide-anchor' onClick={() => setShowEmail(!showEmail)}>{showEmail? "hide" : "show"} email</span>)</th>
                  <th className='study'>Study</th>
                  <th className='event-date'>Procedure Date</th>
                  <th className='ps'>PS</th>
                  <th className='ss'>SS</th>
                  <th className='button'></th>
                </tr>
              </thead>
              <tbody>
                {filteredPatients? filteredPatients.map((patient, index) => {
                  return (
                    <tr key={index}>
                      <td className="custom-id">{patient.custom_patient_id}</td>
                      <td className="email">{showEmail && patient.email}</td>
                      <td className="study">{patient.study}</td>
                      <td className="event-date">{moment(patient.event_date).format("DD/MM/YYYY")}</td>
                      <td className="ps">N/A</td>
                      <td className="ss">N/A</td>
                      <td className="button"><Button variant="primary" onClick={() => navigate(`/patients/${patient.custom_patient_id}`)}>
                        <MdExitToApp />
                      </Button></td>
                    </tr>
                  )
                }) : patients.map((patient, index) => {
                  return (
                    <tr key={index}>
                      <td className="custom-id">{patient.custom_patient_id}</td>
                      <td className="email">{showEmail && patient.email}</td>
                      <td className="study">{patient.study}</td>
                      <td className="event-date">{moment(patient.event_date).format("DD/MM/YYYY")}</td>
                      <td className="ps">N/A</td>
                      <td className="ss">N/A</td>
                      <td className="button"><Button variant="primary" onClick={() => navigate(`/patients/${patient.custom_patient_id}`)}>
                        <MdExitToApp />
                      </Button></td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </>)}    
    </div>
  )
}

export default Patients