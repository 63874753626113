import React, { useState, useEffect } from 'react'
import { Form, Button, Modal } from "react-bootstrap"
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { BASE_URL } from '../../utils/constants';
import { Oval } from "react-loader-spinner"
import { toast } from 'react-toastify';
import { PiFaceMaskFill } from "react-icons/pi";

import moment from 'moment'

import "./AddSurgery.scss"

const AddSurgery = ({newSurgery, user, logUserOut}) => {
  const navigate = useNavigate()
  const params = useParams()
  const { customPatientId, eventId } = params
  const [loading, setLoading] = useState(!newSurgery)
  const [study, setStudy] = useState("")
  const [eventStatus, setEventStatus] = useState("Active")
  const [eventDate, setEventDate] = useState("")
  const [dateUpdated, setDateUpdated] = useState(false)
  const [reasonForInactivity, setReasonForInactivity] = useState("")
  const [treatmentSide, setTreatmentSide] = useState("")
  const [disableButton, setDisableButton] = useState(false)
  const [error, setError] = useState(false)
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const getSurgeryById = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
      const response = await axios.get(`${BASE_URL}/api/v1/event/${eventId}`, config)
      const event = response.data.event
      setStudy(event.study)
      setTreatmentSide(event.treatment_side)
      setEventDate(moment(event.event_date).format("YYYY-MM-DD"))
      setEventStatus(event.event_status)
      setReasonForInactivity(event.reason_for_inactivity)
      setLoading(false)
    }
    catch (error) {
      console.log(error)
      const message = error.response && error.response.data.message ? error.response.data.message : error.message
      if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
        logUserOut()
      }
    }
  }

  useEffect(() => {
    if(!newSurgery) {
      getSurgeryById()
    }
  }, [])

  const deleteHandler = async (e) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
      await axios.delete(`${BASE_URL}/api/v1/event/${eventId}`, config)
      setDisableButton(true)
      toast.success('Surgery deleted', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      setDisableButton(false)
      navigate(`/patients/${customPatientId}`)
      
    }
    catch (error) {
      console.log(error)
      const message = error.response && error.response.data.message ? error.response.data.message : error.message
      if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
        logUserOut()
      }
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    if(eventDate === "" || (dateUpdated && moment(eventDate) < moment().startOf('day')) || study === "" || treatmentSide === "" || (eventStatus === "Inactive" && reasonForInactivity === "")) {
      setError(true)
    }
    else {
      setError(false)
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
        const body = {
          customPatientId: customPatientId,
          eventId: eventId,
          eventType: "surgery",
          eventDate: eventDate,
          eventStatus: eventStatus,
          study: study,
          treatmentSide: treatmentSide,
          reasonForInactivity: reasonForInactivity,
        }
        if(newSurgery) {
          setDisableButton(true)
          await axios.post(`${BASE_URL}/api/v1/event/new`, body, config)
          toast.success('Surgery created', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setDisableButton(false)
        }
        else {
          await axios.put(`${BASE_URL}/api/v1/event/${eventId}`, body, config)
          toast.success('Surgery updated', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
        navigate(`/patients/${customPatientId}`) 
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if(error.response) {
          if(error.response.status === 403) {
            toast.error('Custom Patient ID already exists!', {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
          }
          if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
            logUserOut()
          }
        }
      }
    }
  }

  return (
    <div className="add-surgery">
      {loading? (
        <Oval
          height={80}
          width={80}
          color="#4caeff"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#4caeff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      ) : (
        <>
          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Deleting Event</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this surgery event and all the patient surveys linked to it?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Back
              </Button>
              <Button variant="danger" onClick={deleteHandler}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <div className='add-surgery-header'>
          <h2 className="logo"><PiFaceMaskFill /> {newSurgery? "New" : "Edit"} Surgery <i>({customPatientId})</i></h2>
          </div>
          <Form onSubmit={submitHandler}>
            <div className="form-content">
              <div className="sugery-details">
              <h3>Surgery Details</h3>
              <Form.Group className="form-group">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  style={{ border: error && dateUpdated && (eventDate === "" || moment(eventDate) < moment().startOf('day')) ? "2px solid red" : "" }}
                  type="date"
                  value={eventDate}
                  onChange={(e) => {setDateUpdated(true); setEventDate(e.target.value)}}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Study</Form.Label>
                <Form.Select
                  style={{ border: error && study === "" ? "2px solid red" : "" }}
                  value={study}
                  onChange={(event) => setStudy(event.target.value)}>
                  <option value="" hidden>Select study</option>
                  <option value="elbow">Elbow</option>
                  <option value="shoulder arthroscopy">Shoulder Arthroscopy</option>
                  <option value="shoulder arthroplasty">Shoulder Arthroplasty</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Treatment side</Form.Label>
                <Form.Select
                  style={{ border: error && treatmentSide === "" ? "2px solid red" : "" }}
                  value={treatmentSide}
                  onChange={(event) => setTreatmentSide(event.target.value)}>
                  <option value="" hidden>Select side</option>
                  <option value="Left">Left</option>
                  <option value="Right">Right</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  style={{ border: error && eventStatus === "" ? "2px solid red" : "" }}
                  value={eventStatus}
                  onChange={(event) => setEventStatus(event.target.value)}>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </Form.Select>
              </Form.Group>
              {
                eventStatus === "Inactive" && 
                <Form.Group className="form-group">
                  <Form.Label>Reason for Inactivity</Form.Label>
                  <Form.Select
                    style={{ border: error && reasonForInactivity === "" ? "2px solid red" : "" }}
                    value={reasonForInactivity}
                    onChange={(event) => setReasonForInactivity(event.target.value)}>
                      <option value="" hidden>Select side</option>
                      <option value="Lost to follow up">Lost to follow up</option>
                      <option value="Deceased">Deceased</option>
                      <option value="Opted out">Opted out</option>
                      <option value="Follow-up/Secondary surgery">Follow-up/Secondary surgery</option>
                      <option value="Changed hospital">Changed hospital</option>
                  </Form.Select>
                </Form.Group>
              }
              </div>   
            </div>
            <Button type="submit" variant="primary" disabled={disableButton}>
              {newSurgery? "Create Surgery" : "Update Surgery"}
            </Button>
            {
              !newSurgery &&
              <Button variant="danger" onClick={handleShow}>
                Delete Surgery
              </Button>
            }
            <Button variant="secondary" onClick={() => navigate(-1)}>
              Back
            </Button>
          </Form>
        </>
      )}
    </div>
  )
}

export default AddSurgery