import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { BASE_URL, STUDY_ABBREVIATION_MAP } from '../../utils/constants';
import { PiStethoscopeFill } from "react-icons/pi";
import { Form, Button, Card, Container} from "react-bootstrap"
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Oval } from "react-loader-spinner"
import { toast } from 'react-toastify';
import moment from 'moment';
import validator from 'validator'
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import AsesElbowObjectiveMeasures from '../../components/ObjectiveMeasure/AsesElbowObjectiveMeasures/AsesElbowObjectiveMeasures';
import AsesShoulderObjectiveMeasures from '../../components/ObjectiveMeasure/AsesShoulderObjectiveMeasures/AsesShoulderObjectiveMeasures';

import "./ClinicVisit.scss"

const ClinicVisit = ({newClinicVisit, user, logUserOut}) => {
  const navigate = useNavigate()
  const { customPatientId, eventId } = useParams()
  const [loading, setLoading] = useState(true)
  const [patientSurgeries, setPatientSurgeries] = useState([])
  const [selectedSurgeryId, setSelectedSurgeryId] = useState("")
  const [selectedSurgeryStudy, setSelectedSurgeryStudy] = useState("")
  const [disableButton, setDisableButton] = useState(false)
  const [error, setError] = useState(false)

  const [formData, setFormData] = useState({
    objectiveMeasuresQuestion1: "",
    objectiveMeasuresQuestion2: "",
    objectiveMeasuresQuestion3: "",
    objectiveMeasuresQuestion4: "",
    objectiveMeasuresQuestion5: "",
    objectiveMeasuresQuestion6: "",
    objectiveMeasuresQuestion7: "",
    objectiveMeasuresQuestion8: "",
    objectiveMeasuresQuestion9: "",
    objectiveMeasuresQuestion10: "",
    objectiveMeasuresQuestion11: "",
    objectiveMeasuresQuestion12: "",
    objectiveMeasuresQuestion13: "",
    objectiveMeasuresQuestion14: "",
    objectiveMeasuresQuestion15: "",
    objectiveMeasuresQuestion16: "",
    objectiveMeasuresQuestion17: "",
    objectiveMeasuresQuestion18: "",
    objectiveMeasuresQuestion19: "",
    objectiveMeasuresQuestion20: "",
    objectiveMeasuresQuestion21: "",
    objectiveMeasuresQuestion22: "",
    objectiveMeasuresQuestion23: "",
    objectiveMeasuresQuestion24: "",
    objectiveMeasuresQuestion25: "",
    objectiveMeasuresQuestion26: "",
    objectiveMeasuresQuestion27: "",
    objectiveMeasuresQuestion28: "",
    objectiveMeasuresQuestion29: "",
    objectiveMeasuresQuestion30: "",
    objectiveMeasuresQuestion31: "",
    objectiveMeasuresQuestion32: "",
    objectiveMeasuresQuestion33: "",
    objectiveMeasuresQuestion34: "",
    objectiveMeasuresQuestion35: "",
    objectiveMeasuresQuestion36: "",
    objectiveMeasuresQuestion37: "",
    objectiveMeasuresQuestion38: "",
    objectiveMeasuresQuestion39: "",
  })

  useEffect(() => {
    const getActivePatientSurgeryEventsByCustomId = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
        const response = await axios.get(`${BASE_URL}/api/v1/patient/${customPatientId}/events/surgery/active`, config)
        const surgeries = response.data.surgeries
        setPatientSurgeries(surgeries)
        setLoading(false)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    if(newClinicVisit) {
      getActivePatientSurgeryEventsByCustomId()
    }
  }, [customPatientId])

  useEffect(() => {
    const getClinicVisitDataById = async (eventId) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
        const response = await axios.get(`${BASE_URL}/api/v1/event/${eventId}`, config)
        const {clinicVisit, surgery, objectiveMeasures } = response.data
        setPatientSurgeries([surgery])
        setSelectedSurgeryId(surgery.id)
        const retrievedFormData = {}
        const numberOfQuestions = clinicVisit.study === "elbow"? 39 : 35
        for(let question = 1; question <= numberOfQuestions; question++) {
          retrievedFormData[`objectiveMeasuresQuestion${question}`] = objectiveMeasures[`question_${question}`]
        }
        setFormData(retrievedFormData)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    if(!newClinicVisit) {
      getClinicVisitDataById(eventId)
      setLoading(false)
    }
  }, [eventId])

  useEffect(() => {
    const selectedSurgery = (patientSurgeries.filter(surgery => surgery.id === selectedSurgeryId)[0])
    if(selectedSurgery) {
      setSelectedSurgeryStudy(selectedSurgery.study)
    }
  }, [selectedSurgeryId])

  const handleInputChange = (input, value) => {
    setFormData(prevState => {
      prevState[input] = value
      return{...prevState}
    });
  }

  const validateForm = () => {
    let formError = false
    if(selectedSurgeryStudy === "elbow") {
      for(let question = 1; question <= 39; question++) {
        const answer = formData[`objectiveMeasuresQuestion${question}`]
        switch(question) {
          case 2: case 3: case 4: case 5: case 6: case 7:
            if(answer === "" || parseInt(answer) < -360 || parseInt(answer) > 360) {
              formError = true
            }
            break;
          case 16:
            if(answer === "" || parseFloat(answer) < 0 || parseFloat(answer) > 200) {
              formError = true
            }
            break;
          default:
            if(validator.isEmpty(answer)) { 
              formError = true
            }
        }
      }
    }
    else {
      for(let question = 1; question <= 35; question++) {
        const answer = formData[`objectiveMeasuresQuestion${question}`]
        switch(question) {
          case 2: case 3: case 4: case 5: case 6: case 7: case 10: case 11:
            if(answer === "" || parseInt(answer) < -360 || parseInt(answer) > 360) { 
              formError = true
            }
            break;
          default:
            if(validator.isEmpty(answer)) { 
              formError = true
            }
        }
      }
    }
    return formError
  }

  const handleSubmit = async (event, type) => {
    event.preventDefault();
    const formError = validateForm()
    if(!formError) {
      setError(false);
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
      
      try {
        if(newClinicVisit) {
          const body = {
            customPatientId: customPatientId,
            eventId: eventId,
            relatedEventId: selectedSurgeryId,
            eventType: "clinic visit",
            eventDate: moment().format("YYYY-MM-DD"),
            study: selectedSurgeryStudy,
            eventData: formData
          }
          setDisableButton(true)
          await axios.post(`${BASE_URL}/api/v1/event/new`, body, config)
          toast.success('Clinic visit saved', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setDisableButton(false)
        }
        else {
          const body = {
            eventId: eventId,
            study: selectedSurgeryStudy,
            eventData: formData
          }
          await axios.put(`${BASE_URL}/api/v1/event/${eventId}/objective-measures`, body, config)
          toast.success('Clinic visit updated', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
        navigate(`/patients/${customPatientId}`)
      }
      catch(error) {
        console.log(error.response.data)
      }
    }
    else {
      setError(true)
    }
  };


  return (
    <div className="clinic-visit">
      {loading? (
        <Oval
          height={80}
          width={80}
          color="#4caeff"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#4caeff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      ) : (
        <>
          <div className='clinic-visit-header'>
            <div className='clinic-visit-title'>
              <h2 className="logo"><PiStethoscopeFill /> Clinic Visit <i>({customPatientId})</i></h2>
              <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/patients" }}>Patients</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/patients/${customPatientId}` }}>{customPatientId}</Breadcrumb.Item>
                <Breadcrumb.Item active>{newClinicVisit? "New Clinic Visit" : "Edit Clinic Visit"}</Breadcrumb.Item>
              </Breadcrumb> 
            </div>
            <div className="surgery-filter">
              <Form.Group className="form-group">
                <Form.Select
                  value={selectedSurgeryId}
                  onChange={(event) => setSelectedSurgeryId(parseInt(event.target.value))}>
                  <option value="" hidden>Select surgery</option>
                  {
                    patientSurgeries.map((surgery, index) => {
                      return (
                        <option key={index} value={surgery.id}>{STUDY_ABBREVIATION_MAP[surgery.study]} ({surgery.treatment_side.charAt(0)}) - {moment(surgery.event_date).format("DD/MM/YYYY")}</option>
                      )
                    })
                  }
                </Form.Select>
              </Form.Group>
            </div>
          </div>
          <div className='objective-measure-form'>
            {selectedSurgeryId !== "" ?
              (
                <Form onSubmit={handleSubmit}>
                  <Card>
                    <Card.Header>ASES {selectedSurgeryStudy === "elbow"? "Elbow" : "Shoulder"} Objective Measure</Card.Header>
                    <Card.Body>
                      <Container className="form-content">
                        {
                          selectedSurgeryStudy === "elbow"? (
                            <AsesElbowObjectiveMeasures onChangeHandler={handleInputChange} formValues={formData} error={error}/>
                          ) : (
                            <AsesShoulderObjectiveMeasures onChangeHandler={handleInputChange} formValues={formData} error={error}/>
                          )                
                        }
                      </Container>
                    </Card.Body>
                    <Card.Footer>
                      <Button className="float-start" variant="primary" disabled={disableButton} onClick={handleSubmit}>{newClinicVisit? "Submit" : "Update"}</Button>
                      <Button variant="secondary" onClick={() => navigate(-1)}>Back</Button>
                    </Card.Footer>
                  </Card>
                </Form>
              ) : null
            }
          </div>
        </>
      )}
    </div>
  )
}

export default ClinicVisit