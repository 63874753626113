import React, { useState, useEffect} from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'react-bootstrap/Button';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { BASE_URL } from '../../utils/constants';
import Badge from 'react-bootstrap/Badge'
import axios from 'axios'

import './Header.scss'

const Header = ({ loggedIn, user, logUserOut, numberOfNotifications, setNumberOfNotifications }) => {
  const [patients, setPatients] = useState([])
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState("")
  const [expanded, setExpanded] = useState(false);
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  }

  useEffect(() => {
    const getAllPatients = async () => {
      try {
          const response = await axios.get(`${BASE_URL}/api/v1/patient/search-box`, config)
          setPatients(response.data.patients)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    if(loggedIn) {
      getAllPatients()
    }
  }, [loggedIn])

  useEffect(() => {
    const getSurveyOutliers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/v1/self-assessment/outliers/`, config)
        const surveyOutliers = response.data.surveyOutliers
        setNumberOfNotifications(surveyOutliers.length)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    getSurveyOutliers()
  }, [])

  const handleOnSelect = (patient) => {
    navigate(`/patients/${patient.custom_id}`)
    setExpanded(false)
    setSearchString("")
  }

  const formatResult = (patient) => {
    return (
      <>
        <span className="custom-patient-id">{patient.custom_id}</span>
        <span className="patient-email">{patient.email}</span>
        <span className="patient-study">
          <i>{patient.shoulder_arthroplasty? "SAp " : null}</i>
          <i>{patient.shoulder_arthroscopy? "SAs " : null}</i>
          <i>{patient.elbow? "E " : null}</i>
        </span>
      </>
    )
  }


  return (
    <header className='header'>
      <Navbar expand="lg" bg="dark" expanded={expanded}>
      <Container fluid="xxl">
        <Navbar.Brand>
          <LinkContainer to='/dashboard'>
                <Nav.Link className='logo'>OTOS</Nav.Link>
          </LinkContainer>
        </Navbar.Brand>
        {loggedIn? (
          <>
          <Navbar.Toggle aria-controls="navbarScroll" onClick={() => setExpanded(expanded ? false : "expanded")}/>
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0 mx-auto" navbarScroll>
              <LinkContainer to='/dashboard'>
                  <Nav.Link onClick={() => setExpanded(false)}>DASHBOARD</Nav.Link>
              </LinkContainer>
              <LinkContainer to='/patients'>
                  <Nav.Link onClick={() => setExpanded(false)}>PATIENTS</Nav.Link>
              </LinkContainer>
              <LinkContainer to='/analysis'>
                  <Nav.Link onClick={() => setExpanded(false)}>ANALYSIS</Nav.Link>
              </LinkContainer>
              <LinkContainer to='/notifications'>
                  <Nav.Link onClick={() => setExpanded(false)}>NOTIFICATIONS {numberOfNotifications > 0 && (<Badge pill bg="danger">{numberOfNotifications}</Badge>)}</Nav.Link>
              </LinkContainer>
              <NavDropdown data-bs-theme="dark" title={user.username.toUpperCase()} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => setExpanded(false)} href="action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item onClick={() => setExpanded(false)} href="action/3.2">Another action</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => logUserOut()}>Sign Out</NavDropdown.Item>
            </NavDropdown>
            </Nav>
            <div className='search'>
              <ReactSearchAutocomplete
                items={patients}
                onSearch={(string, results) => setSearchString(string)}
                onSelect={handleOnSelect}
                onClear={() => setSearchString("")}
                inputSearchString={searchString}
                onFocus={() => setSearchString("")}
                fuseOptions={{ 
                  threshold: 0.0,
                  ignoreLocation: true,
                  keys: ["custom_id", "email"] 
                }}
                resultStringKeyName="custom_id" 
                showClear={true}
                formatResult={formatResult}
                placeholder="Search"
                className='react-search-autocomplete'
              />
            </div>
          </Navbar.Collapse>
          </>
        ) : null
        }
      </Container>
    </Navbar>
    </header>
  );
}


export default Header;
