export const BASE_URL = process.env.NODE_ENV === "development"? "http://localhost:5000" : ""

export const STUDY_ABBREVIATION_MAP = {
  "shoulder arthroscopy": "SAs",
  "shoulder arthroplasty": "SAp",
  "elbow": "Elbow "
}

export const studyCamelCaseToSpacedCapitalizedMap = {
  "elbow": "elbow",
  "shoulderArthroplasty" : "Shoulder Arthroplasty",
  "shoulderArthroscopy": "Shoulder Arthroscopy",
}

export const studySpacedCapitalizedToCamelCaseMap = {
  "elbow": "elbow",
  "Shoulder Arthroplasty": "shoulderArthroplasty",
  "Shoulder Arthroscopy": "shoulderArthroscopy",
}