export const getCatalogEntryData = (category, form) => {
    switch(category) {
      case "patientInformationSurgicalTimeAndPersonnel":
        switch(form) {
          case "patientInformation":
                return {
                  category: "patientInformationSurgicalTimeAndPersonnel",
                  form: "patientInformation",
                  formData: {
                    "weight": "", 
                    "height": "",
                    "durationOfSymptoms": "",
                    "profession": "",
                    "principalSport": "",
                    "smoker": "",
                    "diabetic": "",
                    "workmansCompensationCase": "",
                    "preOperativeUseOfNarcotics": "",
                  }
                }  
          case "surgicalTimeAndPersonnel":
              return {
                category: "patientInformationSurgicalTimeAndPersonnel",
                form: "surgicalTimeAndPersonnel",
                formData: {
                  "surgicalTime": "",
                  "numberOfSurgicalTechs": "",
                  "numberOfPas": "",
                  "numberOfSurgicalAssistants": "",
                  "numberOfNurses": "",
                  "numberOfSurgeons": "",
                  "numberOfFellows": "",
                  "numberOfResidents": "",
                  "numberOfNursePractitioners": "",
                  "numberOfOrthopedicTechnologists": "",
                }
              }  
          default: return
        }
      case "handDominance":
          return {
            category: "handDominance",
            formData: {
              "handDominance": "", 
            }
          }  
      case "diagnosis":
        switch(form) {
          case "rotatorCuff":
              return {
                category: "diagnosis",
                form: "rotatorCuff",
                formData: {
                  "acuteOrChronic": "",
                  "numberOfTendonsTorn": "",
                  "rotatorCuffTendonQuality": "",
                  "snyderClassification": "",
                  "cofieldClassification": "",
                  "rotatorCuffRetractionStage": "",
                  "rotatorDimensionsAnteriorToPosterior": "",
                  "tearSizeAnteriorToPosterior": "",
                  "tearSizeMedialToLateral": "",
                  "rotatorCuffTearShape": "",
                  "supraspinatusTear": "",
                  "goutallierStageSupraspinatus": "",
                  "infraspinatusTear": "",
                  "goutallierStageInfraspinatus": "",
                  "laFosseClassification": "",
                  "goutallierStageSubScapularis": "",
                  "teresMinorTear": "",
                  "goutallierStageTeresMinor": "",
                }
              }  
          case "subacromialImpingement":
              return {
                category: "diagnosis",
                form: "subacromialImpingement",
                formData: {
                  "acromionType": "",
                }
              }  
          case "labralInjuryAndInstability":
              return {
                category: "diagnosis",
                form: "labralInjuryAndInstability",
                formData: {
                  "directionOfInstability": "",
                  "glenoHumeralDislocation": "",
                  "slapLesion": "",
                  "labralTearAnterior": "",
                  "labralTearPosterior": "",
                  "glenoidBoneDefect": "",
                  "humeralHeadHillsSachsLesion": "",
                  "hagl": "",
                  "alpsa": false,
                  "glenoHumeralChondropathy": false
                }
              }  
          case "biceps":
              return {
                category: "diagnosis",
                form: "biceps",
                formData: {
                  "degreeOfTear": "",
                  "condition": "",
                }
              }  
          case "acJoint":
              return {
                category: "diagnosis",
                form: "acJoint",
                formData: {
                  "acuteOrChronic": "",
                  "acInstabilityType": "",
                }
              }  
          case "osAcromiale":
              return {
                category: "diagnosis",
                form: "osAcromiale",
                formData: {
                  "type": "",
                }
              }  
          case "glenohumeralArthritis":
              return {
                category: "diagnosis",
                form: "glenohumeralArthritis",
                formData: {
                  "typglenoidOuterbridgeGradee": "",
                  "humerusOuterbridgeGrade": "",
                  "kellgrenLawrenceClassification": "",
                }
              }  
          case "articularCartilageOsteochondralLesion":
              return {
                category: "diagnosis",
                form: "articularCartilageOsteochondralLesion",
                formData: {
                  "location": "",
                  "probableCause": "",
                  "icrsGrade": "",
                  "length": "",
                  "width": "",
                  "area": "",
                  "depth": "",
                  "fullThicknessCartilageDefectWithWellDefinedBorders": false
                }
              }  
          case "otherIntraArticular":
              return {
                category: "diagnosis",
                form: "otherIntraArticular",
                formData: {
                  "synovialDisease": false,
                  "synovialCyst": false,
                  "looseBodyGreaterThan1Cm": false
                }
              }  
          case "fracture":
              return {
                category: "diagnosis",
                form: "fracture",
                formData: {
                  "clavicleDistalThird": "",
                  "clavicleMiddleThird": "",
                  "clavicleProximalThird": "",
                  "proximalHumerus": "",
                  "glenoid": "",
                  "fractureStatus": "",
                  "acromion": false,
                  "coracoid": false,
                  "scapularBody": false,
                }
              }  
          case "miscellaneous":
              return {
                category: "diagnosis",
                form: "miscellaneous",
                formData: {
                  "scJoint": "",
                  "pecMajorRupture": "",
                  "romAbnormalities": "",
                  "hematoma": false,
                  "infection": false,
                  "fattyAtrophy": false,
                  "suprascapularNerveCompression": false,
                  "subacromialBursitis": false,
                  "scapulothoracicBursitis": false,
                  "woundProblem": false,
                }
              }  
          case "arthritis":
              return {
                category: "diagnosis",
                form: "arthritis",
                formData: {
                  "type": "",
                }
              }  
          case "rotatorCuffArthropathy":
              return {
                category: "diagnosis",
                form: "rotatorCuffArthropathy",
                formData: {
                  "subscap": "",
                }
              }  
          case "failedRotatorCuffRepair":
              return {
                category: "diagnosis",
                form: "failedRotatorCuffRepair",
                formData: {
                  "subscap": "",
                }
              }  
          case "failedShoulderArthroplasty":
              return {
                category: "diagnosis",
                form: "failedShoulderArthroplasty",
                formData: {
                  "type": "",
                  "sizeOfFocalHumeralHeadResurfacingImplant": "",
                  "indicationForRevision": "",
                  "subscap": "",
                }
              }  
          case "clavicleFracture":
              return {
                category: "diagnosis",
                form: "clavicleFracture",
                formData: {
                  "clavicleDistalThird": "",
                  "clavicleMiddleThird": "",
                  "clavicleProximalThird": "",
                  "fractureStatus": "",
                }
              }  
          case "humeralFracture":
              return {
                category: "diagnosis",
                form: "humeralFracture",
                formData: {
                  "type": "",
                  "acute": "",
                  "priorFractureSequelae": "",
                  "fractureStatus": "",
                }
              }  
          case "scapulaFracture":
              return {
                category: "diagnosis",
                form: "scapulaFracture",
                formData: {
                  "type": "",
                  "fractureStatus": "",
                }
              }  
          case "chronicRotatorCuffTear":
              return {
                category: "diagnosis",
                form: "chronicRotatorCuffTear",
                formData: {
                  "subscap": "",
                  "hamadaClassification": "",
                }
              }  
          case "tendon":
              return {
                category: "diagnosis",
                form: "tendon",
                formData: {
                  "tendon": "",
                  "acuteOrChronic": "",
                  "type": "",
                  "other": "",
                }
              }  
          case "elbowLigamentInstability":
              return {
                category: "diagnosis",
                form: "elbowLigamentInstability",
                formData: {
                  "ligament": "",
                  "acuteOrChronic": "",
                  "type": "",
                  "directionOfInstability": "",
                  "other": "",
                }
              }  
          case "elbowOsteochondralLesion":
              return {
                category: "diagnosis",
                form: "elbowOsteochondralLesion",
                formData: {
                  "location": "",
                  "probableCause": "",
                  "length": "",
                  "width": "",
                  "area": "",
                  "depth": "",
                  "calcificTendonosis": false,
                  "icrsGrade": "",
                }
              }  
          case "elbowOsteoarthritis":
              return {
                category: "diagnosis",
                form: "elbowOsteoarthritis",
                formData: {
                  "location": "",
                  "grade": "",
                  "osteophytes": "",
                }
              }  
          case "elbowOtherInflammatoryArthritis":
              return {
                category: "diagnosis",
                form: "elbowOtherInflammatoryArthritis",
                formData: {
                  "type": "",
                }
              }  
          case "elbowLooseBody":
              return {
                category: "diagnosis",
                form: "elbowLooseBody",
                formData: {
                  "compartement": "",
                }
              }  
          case "elbowCapsuleSynovium":
              return {
                category: "diagnosis",
                form: "elbowCapsuleSynovium",
                formData: {
                  "synovitis": "",
                  "capsularPlicaPosterolateralImpingement": "",
                  "capsularContracture": "",
                }
              }  
          case "elbowNerve":
              return {
                category: "diagnosis",
                form: "elbowNerve",
                formData: {
                  "acuteOrChronic": "",
                  "type": "",
                  "capsularContracture": "",
                }
              }  
          case "elbowFracture":
              return {
                category: "diagnosis",
                form: "elbowFracture",
                formData: {
                  "intraArticularFracture": "",
                  "avulsionFracture": "",
                  "distalHumerusFracture": false,
                  "proximalBothBonesForearmFracture": false,
                  "stressFracture": "",
                  "apophysealInjuries": "",
                  "fractureStatus": "",
                }
              }  
          case "olecranonSpur":
              return {
                category: "diagnosis",
                form: "olecranonSpur",
                formData: {
                  "location": "",
                }
              }  
          case "elbowOther":
              return {
                category: "diagnosis",
                form: "elbowOther",
                formData: {
                  "rheumatoidArthritis": "",
                  "septicArthritis": "",
                  "olecranonBursitis": "",
                }
              }  
          default: return
        }
      case "operations":
        switch(form) {
          case "rotatorCuffCompleteTear":
              return {
                category: "operations",
                form: "rotatorCuffCompleteTear",
                formData: {
                  "primaryOrRevision": "",
                  "open": false,
                  "procedures": "",
                  "anchorRepairTechniqueUsed": "",
                  "rotatorCuffTearImplants": "",
                  "numberOfAnchorsUsed": "",
                }
              }  
          case "rotatorCuffPartialTear":
              return {
                category: "operations",
                form: "rotatorCuffPartialTear",
                formData: {
                  "primaryOrRevision": "",
                  "open": false,
                  "procedures": "",
                  "anchorRepairTechniqueUsed": "",
                  "rotatorCuffTearImplants": "",
                  "numberOfAnchorsUsed": ""
                }
              }  
          case "superiorCapsuleReconstruction":
              return {
                category: "operations",
                form: "superiorCapsuleReconstruction",
                formData: {
                  "primaryOrRevision": "",
                  "open": false,
                  "procedures": "",
                  "glenoidSideGraftFixation": "",
                  "tieSuturesFromTheGlenoidAnchorToTheSupraspinatusTendon": false,
                  "humeralSideGraftFixation": "",
                  "graftUsed": "",
                  "armPositionForMeasuringPositioningAndSecuringGraft": "",
                  "posteriorMarginConvergencePerformed": false,
                  "anteriorMarginConvergencePerformed": false,
                  "superiorLabrumManagement": "",
                }
              }  
          case "subscap":
              return {
                category: "operations",
                form: "subscap",
                formData: {
                  "primaryOrRevision": "",
                  "open": false,
                  "procedures": "",
                  "anchorRepairTechniqueUsed": "",
                }
              }  
          case "subAcromialDecompression":
              return {
                category: "operations",
                form: "subAcromialDecompression",
                formData: {
                  "primaryOrRevision": "",
                  "procedures": "",
                }
              }  
          case "tendonTransfer":
              return {
                category: "operations",
                form: "tendonTransfer",
                formData: {
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "procedures": "",
                }
              }  
          case "biceps":
              return {
                category: "operations",
                form: "biceps",
                formData: {
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "procedures": "",
                  "techniquesAndFixationDevice": ""
                }
              }  
          case "coracoplasty":
              return {
                category: "operations",
                form: "coracoplasty",
                formData: {
                  "primaryOrRevision": "",
                }
              }  
          case "distalClavicleExcision":
              return {
                category: "operations",
                form: "distalClavicleExcision",
                formData: {
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                }
              }  
          case "acJointStabilization":
              return {
                category: "operations",
                form: "acJointStabilization",
                formData: {
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "procedures": "",
                  "numberOfTightRopesUsed": "",
                }
              }  
          case "osAcromiale":
              return {
                category: "operations",
                form: "osAcromiale",
                formData: {
                  "primaryOrRevision": "",
                  "procedures": "",
                }
              }  
          case "osteotomy":
              return {
                category: "operations",
                form: "osteotomy",
                formData: {
                  "primaryOrRevision": "",
                  "location": "",
                }
              }  
          case "cartilageTreatment":
              return {
                category: "operations",
                form: "cartilageTreatment",
                formData: {
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "location": "",
                  "procedures": "",
                  "microfractureAwlOrDrillingPowerPickUsed": "",
                  "orthobiologicDetails": "",
                  "numberOfAnchorsUsed": "",
                  "orthobiologicManufacturer": "",
                  "angelSystemHematocritSetting": "",
                  "angelSystemInitialWholeBloodVolume": "",
                  "angelSystemPrpVolumeProduced": "",
                  "prpTypeOfActivation": "",
                }
              }  
          case "osteochondralTreatment":
              return {
                category: "operations",
                form: "osteochondralTreatment",
                formData: {
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "location": "",
                  "osteochondralGraftType": "",
                  "numberOfPlugsUsed": "",
                  "avgDiameterOfPlugsUsed": "",
                  "totalApproxSizeOfImplantedPlug": "",
                }
              }  
          case "otherIntraArticular":
              return {
                category: "operations",
                form: "otherIntraArticular",
                formData: {
                  "primaryOrRevision": "",
                  "synovialDebridement": "",
                  "cystRemoval": "",
                  "looseBodyRemoval": "",
                }
              }  
          case "fractureManagement":
              return {
                category: "operations",
                form: "fractureManagement",
                formData: {
                  "primaryOrRevision": "",
                  "clavicleFractureProcedure": "",
                  "greaterTuberosityFractureProcedure": "",
                  "proximalHumerusFractureProcedure": "",
                  "glenoidFractureProcedure": "",
                  "scapulaFractureProcedure": "",
                }
              }  
          case "miscellaneous":
              return {
                category: "operations",
                form: "miscellaneous",
                formData: {
                  "primaryOrRevision": "",
                  "capsularRelease": "",
                  "diagnosticArthroscopy": false,
                  "extensiveGlenohumeralDebridement": false,
                  "irrigationAndDebridement": false,
                  "lysisOfAdhesions": false,
                  "manipulationUnderAnesthesia": false,
                  "subacromialBursectomy": false,
                  "scapulothoracicBursectomy": false,
                  "pecMajorRepair": false,
                  "suprascapularNerveRelease": "",
                  "scJoint": "",
                }
              }  
          case "elbowCaseDetails":
              return {
                category: "operations",
                form: "elbowCaseDetails",
                formData: {
                  "anesthesiaType": "",
                  "patientPosition": "",
                }
              }  
          case "elbowTendonProcedures":
              return {
                category: "operations",
                form: "elbowTendonProcedures",
                formData: {
                  "type": "",
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "arthroscopicPortalsUsed": "",
                  "procedures": "",
                  "procedures2": "",
                  "procedures3": "",
                }
              }  
          case "elbowCollateralLigaments":
              return {
                category: "operations",
                form: "elbowCollateralLigaments",
                formData: {
                  "ligament": "",
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "arthroscopicPortalsUsed": "",
                  "procedures": "",
                  "details": "",
                }
              }  
          case "elbowCartilageTreatment":
              return {
                category: "operations",
                form: "elbowCartilageTreatment",
                formData: {
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "arthroscopicPortalsUsed": "",
                  "location": "",
                  "type": "",
                  "microfractureAwlOrDrillingPowerPickUsed": "",
                  "orthobiologicDetails": "",
                  "numberOfAnchorsUsed": "",
                  "orthobiologicManufacturer": "",
                  "angelSystemHematocritSetting": "",
                  "angelSystemInitialWholeBloodVolume": "",
                  "angelSystemPrpVolumeProduced": "",
                  "prpTypeOfActivation": "",
                  "boneMarrowAspirateConcentrate": "",
                }
              }  
          case "elbowOsteochondralTreatment":
              return {
                category: "operations",
                form: "elbowOsteochondralTreatment",
                formData: {
                  "primaryOrRevision": "",
                  "location": "",
                  "numberOfPlugsUsed": "",
                  "avgDiameterOfPlugsUsed": "",
                }
              }  
          case "elbowOsteophyteDebridementExcision":
              return {
                category: "operations",
                form: "elbowOsteophyteDebridementExcision",
                formData: {
                  "openOrArthroscopic": "",
                  "arthroscopicPortalsUsed": "",
                  "location": "",
                }
              }  
          case "elbowRemovalLooseBody":
              return {
                category: "operations",
                form: "elbowRemovalLooseBody",
                formData: {
                  "compartement": "",
                }
              }  
          case "elbowCapsuleSynovium":
              return {
                category: "operations",
                form: "elbowCapsuleSynovium",
                formData: {
                  "manipulationUnderAnesthesia": "",
                  "openOrArthroscopic": "",
                  "arthroscopicPortalsUsed": "",
                  "procedures": "",
                  "location": "",
                }
              }  
          case "elbowNerve":
              return {
                category: "operations",
                form: "elbowNerve",
                formData: {
                  "primaryOrRevision": "",
                  "procedures": "",
                }
              }  
          case "elobwOrif":
              return {
                category: "operations",
                form: "elobwOrif",
                formData: {
                  "primaryOrRevision": "",
                  "location": "",
                  "type": "",
                }
              }  
          case "radialHeadExcision":
              return {
                category: "operations",
                form: "radialHeadExcision",
                formData: {
                  "openOrArthroscopic": "",
                  "arthroscopicPortalsUsed": "",
                }
              }  
          case "radialHeadImplant":
              return {
                category: "operations",
                form: "radialHeadImplant",
                formData: {
                  "primaryOrRevision": "",
                }
              }  
          case "totalElbowArthroplasty":
              return {
                category: "operations",
                form: "totalElbowArthroplasty",
                formData: {
                  "primaryOrRevision": "",
                }
              }  
          default: return
        }
      case "operationsLabrumAndInstability":
        switch(form) {
          case "slap":
              return {
                category: "operationsLabrumAndInstability",
                form: "slap",
                formData: {
                  "primaryOrRevision": "", 
                  "procedures": "", 
                  "repairDetails": "",
                  "typeOfAnchorUsed": "",
                }
              }  
          case "labralRepair":
              return {
                category: "operationsLabrumAndInstability",
                form: "labralRepair",
                formData: {
                  "primaryOrRevision": "", 
                  "open": false, 
                  "procedures": "", 
                  "repairDetails": "",
                }
              }  
          case "capsulorrhaphy":
              return {
                category: "operationsLabrumAndInstability",
                form: "capsulorrhaphy",
                formData: {
                  "primaryOrRevision": "", 
                  "openOrArthroscopic": "", 
                  "procedures": "", 
                  "withAnchors": false,
                }
              }  
          case "haglRepair":
              return {
                category: "operationsLabrumAndInstability",
                form: "haglRepair",
                formData: {
                  "primaryOrRevision": "", 
                  "open": false, 
                  "haglRepairType": "", 
                }
              }  
          case "alpsaRepair":
              return {
                category: "operationsLabrumAndInstability",
                form: "alpsaRepair",
                formData: {
                  "primaryOrRevision": "", 
                  "open": false, 
                  "procedures": "", 
                }
              }  
          case "remplissage":
              return {
                category: "operationsLabrumAndInstability",
                form: "remplissage",
                formData: {
                  "primaryOrRevision": "", 
                  "openOrArthroscopic": "", 
                  "procedures": "", 
                }
              }  
          case "subscapularisAugmentation":
              return {
                category: "operationsLabrumAndInstability",
                form: "subscapularisAugmentation",
                formData: {
                  "primaryOrRevision": "", 
                  "procedures": "", 
                  "repairDetails": "", 
                  "extrarotationDegree": "", 
                  "subscapularisElongation": "", 
                }
              }  
          case "glenoidBoneGraftProcedure":
              return {
                category: "operationsLabrumAndInstability",
                form: "glenoidBoneGraftProcedure",
                formData: {
                  "primaryOrRevision": "", 
                  "openOrArthroscopic": "", 
                  "procedures": "", 
                  "typeOfBoneGraftUsed": "", 
                }
              }  
          case "humeralBoneGraftProcedure":
              return {
                category: "operationsLabrumAndInstability",
                form: "humeralBoneGraftProcedure",
                formData: {
                  "primaryOrRevision": "", 
                  "typeOfBoneGraftUsed": "", 
                }
              }  
          default: return;
        }
      case "implants":
        switch(form) {
          case "acJointStabilization":
              return {
                category: "implants",
                form: "acJointStabilization",
                formData: {
                  "arthrexDevices": "",
                }
              }  
          case "biceps":
              return {
                category: "implants",
                form: "biceps",
                formData: {
                  "arthrexDevices": "",
                  "swiveLockType": "",
                  "deviceMaterial": "",
                  "diameter": "",
                  "implantAlsoUsedForSubscapularisRepair": false,
                  "implantAlsoUsedForSupraspinatusRepair": false,
                }
              }  
          case "capsularRepairHaglAlpsaRemplissageAsa":
              return {
                category: "implants",
                form: "capsularRepairHaglAlpsaRemplissageAsa",
                formData: {
                  "associatedProcedure": "",
                  "knotlessAnchorType": "",
                  "knotlessAnchorDiameter": "",
                  "knotlessAnchorMaterial": "",
                  "knotlessAnchorsUsed": "",
                  "knotlessAnchorSuture": "",
                  "sutureAnchorType": "",
                  "sutureAnchorDiameter": "",
                  "sutureAnchorMaterial": "",
                  "sutureAnchorsUsed": "",
                  "screwType": "",
                  "screwDiameter": "",
                  "screwsUsed": "",
                }
              }  
          case "chondral":
              return {
                category: "implants",
                form: "chondral",
                formData: {
                  "location": "",
                  "type": "",
                }
              }  
          case "fractureManagement":
              return {
                category: "implants",
                form: "fractureManagement",
                formData: {
                  "manufacturer": "",
                  "manufacturer2": "",
                  "manufacturer3": "",
                  "manufacturer4": "",
                }
              }  
          case "glenoidBoneGraftProcedure":
              return {
                category: "implants",
                form: "glenoidBoneGraftProcedure",
                formData: {
                  "implantType": "",
                  "manufacturer": "",
                }
              }  
          case "humeralBoneGraftProcedure":
              return {
                category: "implants",
                form: "humeralBoneGraftProcedure",
                formData: {
                  "implantType": "",
                  "manufacturer": "",
                }
              }  
          case "labralRepair":
              return {
                category: "implants",
                form: "labralRepair",
                formData: {
                  "knotlessAnchorType": "",
                  "knotlessAnchorDiameter": "",
                  "knotlessAnchorMaterial": "",
                  "knotlessAnchorsUsed": "",
                  "knotlessAnchorSuture": "",
                  "sutureAnchorType": "",
                  "sutureAnchorDiameter": "",
                  "sutureAnchorMaterial": "",
                  "sutureAnchorsUsed": "",
                  "sutureLoop": "",
                }
              }  
          case "osAcromiale":
              return {
                category: "implants",
                form: "osAcromiale",
                formData: {
                  "device": "",
                  "manufacturer": "",
                }
              }  
          case "osteotomy":
              return {
                category: "implants",
                form: "osteotomy",
                formData: {
                  "device": "",
                  "manufacturer": "",
                }
              }  
          case "pastaRepairKit":
              return {
                category: "implants",
                form: "pastaRepairKit",
                formData: {
                  "manufacturer": "",
                }
              }  
          case "pecMajor":
              return {
                category: "implants",
                form: "pecMajor",
                formData: {
                  "device": "",
                }
              }  
          case "rotatorCuffImplantsLateralRow":
              return {
                category: "implants",
                form: "rotatorCuffImplantsLateralRow",
                formData: {
                  "type": "",
                  "knotlessAnchorType": "",
                  "knotlessAnchorDiameter": "",
                  "knotlessAnchorMaterial": "",
                  "knotlessAnchorsUsed": "",
                  "knotlessAnchorSuture": "",
                  "sutureAnchorType": "",
                  "sutureAnchorDiameter": "",
                  "sutureAnchorMaterial": "",
                  "sutureAnchorsUsed": "",
                  "screwType": "",
                  "screwMaterial": "",
                  "screwDiameter": "",
                  "screwsUsed": "",
                }
              }  
          case "rotatorCuffImplantsMedialRow":
              return {
                category: "implants",
                form: "rotatorCuffImplantsMedialRow",
                formData: {
                  "type": "",
                  "knotlessAnchorType": "",
                  "knotlessAnchorDiameter": "",
                  "knotlessAnchorMaterial": "",
                  "knotlessAnchorsUsed": "",
                  "knotlessAnchorSuture": "",
                  "sutureAnchorType": "",
                  "sutureAnchorDiameter": "",
                  "sutureAnchorMaterial": "",
                  "sutureAnchorsUsed": "",
                  "screwType": "",
                  "screwMaterial": "",
                  "screwDiameter": "",
                  "screwsUsed": "",
                }
              }  
          case "cuffMend":
              return {
                category: "implants",
                form: "cuffMend",
                formData: {
                  "knotlessAnchorType": "",
                  "knotlessAnchorsUsed": "",
                  "knotlessAnchorType2": "",
                  "knotlessAnchorsUsed2": "",
                  "knotlessAnchorSuture": "",    
                }
              }  
          case "slap":
              return {
                category: "implants",
                form: "slap",
                formData: {
                  "knotlessAnchorType": "",
                  "knotlessAnchorDiameter": "",
                  "knotlessAnchorMaterial": "",
                  "knotlessAnchorsUsed": "",
                  "knotlessAnchorSuture": "",
                  "sutureAnchorType": "",
                  "sutureAnchorDiameter": "",
                  "sutureAnchorMaterial": "",
                  "sutureAnchorsUsed": "",
                }
              }  
          case "subscap":
              return {
                category: "implants",
                form: "subscap",
                formData: {
                  "type": "",
                  "knotlessAnchorType": "",
                  "knotlessAnchorDiameter": "",
                  "knotlessAnchorMaterial": "",
                  "knotlessAnchorsUsed": "",
                  "knotlessAnchorSuture": "",
                  "sutureAnchorType": "",
                  "sutureAnchorDiameter": "",
                  "sutureAnchorMaterial": "",
                  "sutureAnchorsUsed": "",
                  "screwType": "",
                  "screwMaterial": "",
                  "screwDiameter": "",
                  "screwsUsed": "",
                }
              }  
          case "superiorCapsuleReconstructionGlenoid":
              return {
                category: "implants",
                form: "superiorCapsuleReconstructionGlenoid",
                formData: {
                  "sutureTakMaterial": "",
                  "sutureAnchorsUsed": "",
                  "swiveLockMaterial": "",
                  "knotlessAnchorSuture": "",
                  "knotlessAnchorsUsed": "",
                  "swiveLockMaterial2": "",
                  "knotlessAnchorSuture2": "",
                  "knotlessAnchorsUsed2": "",
                  "swiveLockMaterial3": "",
                  "knotlessAnchorSuture3": "",
                  "knotlessAnchorsUsed3": "",
                  "pushlockMaterial": "",
                  "knotlessAnchorSuture4": "",
                  "knotlessAnchorsUsed4": "",
                  "corkscrewkMaterial": "",
                  "knotlessAnchorsUsed5": "",
                  "corkscrewkMaterial2": "",
                  "knotlessAnchorsUsed6": "",
                  "fiberTakDr": false,
                  "knotlessAnchorsUsed7": "",
                  "knotlessAnchorsUsed8": "",
                }
              }  
          case "superiorCapsuleReconstructionHumeralSideLateralRow":
              return {
                category: "implants",
                form: "superiorCapsuleReconstructionHumeralSideLateralRow",
                formData: {
                  "type": "",
                  "knotlessAnchorType": "",
                  "knotlessAnchorDiameter": "",
                  "knotlessAnchorMaterial": "",
                  "knotlessAnchorsUsed": "",
                  "knotlessAnchorSuture": "",
                  "sutureAnchorDiameter": "",
                  "sutureAnchorMaterial": "",
                  "sutureAnchorsUsed": "",
                }
              }  
          case "superiorCapsuleReconstructionHumeralSideMedialRow":
              return {
                category: "implants",
                form: "superiorCapsuleReconstructionHumeralSideMedialRow",
                formData: {
                  "type": "",
                  "knotlessAnchorType": "",
                  "knotlessAnchorDiameter": "",
                  "knotlessAnchorMaterial": "",
                  "knotlessAnchorsUsed": "",
                  "knotlessAnchorSuture": "",
                  "sutureAnchorType": "",
                  "sutureAnchorDiameter": "",
                  "sutureAnchorMaterial": "",
                  "sutureAnchorsUsed": "",
                }
              }  
          case "elbowTendonProcedures":
              return {
                category: "implants",
                form: "elbowTendonProcedures",
                formData: {
                  "associatedProcedure": "",
                  "arthrexDevices": "",
                  "deviceMaterial": "",
                  "diameter": "",
                }
              }  
          case "elbowCollateralLigaments":
              return {
                category: "implants",
                form: "elbowCollateralLigaments",
                formData: {
                  "ligament": "",
                  "arthrexDevices": "",
                  "deviceMaterial": "",
                  "diameter": "",
                }
              }  
          case "elbowCartilageTreatment":
              return {
                category: "implants",
                form: "elbowCartilageTreatment",
                formData: {
                  "location": "",
                  "implantUsed": "",
                  "manufacturer": "",
                  "diameter": "",
                }
              }  
          case "elbowOsteochondralTreatment":
              return {
                category: "implants",
                form: "elbowOsteochondralTreatment",
                formData: {
                  "location": "",
                  "implantUsed": "",
                  "manufacturer": "",
                  "diameter": "",
                }
              }  
          case "elbowOrif":
              return {
                category: "implants",
                form: "elbowOrif",
                formData: {
                  "location": "",
                  "implantUsed": "",
                  "manufacturer": "",
                  "diameter": "",
                  "deviceMaterial": "",
                  "numberUsed": "",
                }
              }  
          case "elbowRadialHeadImplant":
              return {
                category: "implants",
                form: "elbowRadialHeadImplant",
                formData: {
                  "manufacturer": "",
                }
              }  
          case "elbowTotalElbowArthroplasty":
              return {
                category: "implants",
                form: "elbowTotalElbowArthroplasty",
                formData: {
                  "manufacturer": "",
                }
              }  
          default: return
        }
      case "surgicalDressing":
          return {
            category: "surgicalDressing",
            form: "slingBraceApplication",
            formData: {
              "slingBrace": "",
            }
          }  
      case "postOperativeManagement":
          return {
            category: "postOperativeManagement",
            form: "postOpPath",
            formData: {
              "postOpPath": "",
              "inpatientStayDays": ""
            }
          }  
      case "grafts":
        switch(form) {
          case "boneGraftAutografts":
              return {
                category: "grafts",
                form: "boneGraftAutografts",
                formData: {
                  "associatedProcedure": "",
                  "harvestedFrom": "",
                  "boneGraftType": "",
                  "volumeUsed": "",
                }
              }  
          case "boneGraftAllografts":
              return {
                category: "grafts",
                form: "boneGraftAllografts",
                formData: {
                  "associatedProcedure": "",
                  "harvestedFrom": "",
                  "demineralizedBone": "",
                  "mineralizedBone": "",
                  "cellularDemineralizedBone": "",
                  "volumeUsed": "",
                  "numberUsed": "",
                  "width": "",
                  "length": "",
                  "height": "",
                  "manufacturer": "",
                  "processing": "",
                }
              }  
          case "boneGraftSyntheticBoneVoidFillers":
              return {
                category: "grafts",
                form: "boneGraftSyntheticBoneVoidFillers",
                formData: {
                  "associatedProcedure": "",
                  "boneGraftType": "",
                  "material": "",
                  "manufacturer": "",
                  "volumeUsed": "",
                }
              }  
          case "cartilageGraftChondralGrafts":
              return {
                category: "grafts",
                form: "cartilageGraftChondralGrafts",
                formData: {
                  "location": "",
                  "autologousCartilageGrafts": "",
                  "alloglenicCartilageGrafts": "",
                  "orthobiologicDetails": "",
                  "manufacturer": "",
                  "volumeUsed": "",
                  "manufacturer2": "",
                  "angelSystemHematocritSetting": "",
                  "angelSystemInitialWholeBloodVolume": "",
                  "angelSystemPrpVolumeProduced": "",
                  "prpTypeOfActivation": "",
                  "syntheticCartilageGrafts": "",
                }
              }  
          case "cartilageGraftOsteochondralGrafts":
              return {
                category: "grafts",
                form: "cartilageGraftOsteochondralGrafts",
                formData: {
                  "location": "",
                  "type": "",
                  "manufacturer": "",
                  "processing": "",
                  "donorSite": "",
                  "alloglenicCartilageGrafts": "",
                  "orthobiologicDetails": "",
                  "boneGraftType": "",
                  "angelSystemHematocritSetting": "",
                  "angelSystemInitialWholeBloodVolume": "",
                  "angelSystemPrpVolumeProduced": "",
                  "prpTypeOfActivation": "",
                  "boneMarrowAspirateConcentrate": "",
                }
              }  
          case "softTissueTendonGrafts":
              return {
                category: "grafts",
                form: "softTissueTendonGrafts",
                formData: {
                  "associatedProcedure": "",
                  "boneGraftType": "",
                  "graftDetails": "",
                  "manufacturer": "",
                  "processing": "",
                  "sterilityAssuranceLevel": "",
                  "diameter": "",
                  "length": "",
                }
              }  
          case "softTissueAugmentationGrafts":
              return {
                category: "grafts",
                form: "softTissueAugmentationGrafts",
                formData: {
                  "associatedProcedure": "",
                  "allograftDermis": "",
                  "synthetic": "",
                  "xenograft": "",
                  "manufacturer": "",
                  "thickness": "",
                  "length": "",
                  "width": "",
                  "sterilityAssuranceLevel": "",
                  "processing": "",
                }
              }  
          case "softTissueAdiposeTissueGraft":
              return {
                category: "grafts",
                form: "softTissueAdiposeTissueGraft",
                formData: {
                  "type": "",
                }
              }  
          case "softTissueMeniscalAllograftFasciaLata":
              return {
                category: "grafts",
                form: "softTissueMeniscalAllograftFasciaLata",
                formData: {
                  "type": "",
                  "size": "",
                  "manufacturer": "",
                  "processing": "",
                }
              }  
          case "elbowSoftTissueTendonGrafts":
              return {
                category: "grafts",
                form: "elbowSoftTissueTendonGrafts",
                formData: {
                  "associatedProcedure": "",
                  "autograft": "",
                  "allograft": "",
                  "manufacturer": "",
                  "diameter": "",
                  "length": "",
                }
              }  
          case "boneGraftFreshAllografts":
              return {
                category: "grafts",
                form: "boneGraftFreshAllografts",
                formData: {
                  "associatedProcedure": "",
                  "type": "",
                }
              }  
          default: return;
        }
      case "operationsAndImplants":
        switch(form) {
          case "approachSupscapRepairAndApplicationBraces":
                return {
                  category: "operationsAndImplants",
                  form: "approachSupscapRepairAndApplicationBraces",
                  formData: {
                    "approach": "",
                    "subscapRepairMethod": "",
                    "applicationOrBrace": "",
                  }
                }  
          case "hemiarthroplasty":
              return {
                category: "operationsAndImplants",
                form: "hemiarthroplasty",
                formData: {
                  "primaryOrRevision": "",
                  "procedures": "",
                  "stemDetails": "",
                  "size": "",
                  "width": "",
                  "height": "",
                  "titaniumHead": false,
                  "cageScrew": "",
                  "trunionSize": "",
                  "boneGraft": "",
                  "manufacturer": "",
                }
              }  
          case "humeralHeadResurfacing":
              return {
                category: "operationsAndImplants",
                form: "humeralHeadResurfacing",
                formData: {
                  "primaryOrRevision": "",
                  "type": "",
                  "size": "",
                  "width": "",
                  "height": "",
                  "manufacturer": "",
                  "procedures": "",
                }
              }  
          case "biologicTotalShoulder":
              return {
                category: "operationsAndImplants",
                form: "biologicTotalShoulder",
                formData: {
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "procedures": "",
                }
              }  
          case "totalShoulderReplacement":
              return {
                category: "operationsAndImplants",
                form: "totalShoulderReplacement",
                formData: {
                  "primaryOrRevision": "",
                  "stemDetails": "",
                  "size": "",
                  "width": "",
                  "height": "",
                  "titaniumHead": false,
                  "glenoid": "",
                  "glenoidSize": "",
                  "glenoidDeficiency": "",
                  "walchClassification": "",
                  "glenoidRetroversion": "",
                  "boneGraft": "",
                  "manufacturer": "",
                  "type": "",
                  "convertibleUniversalGlenoid": "",
                  "humeralOffset": "",
                  "cageScrew": "",
                  "trunionSize": "",
                  "planning3d": "",
                  "procedures": "",
                  "cerclage": "",
                }
              }  
          case "inverseReverseTotalShoulderReplacement":
              return {
                category: "operationsAndImplants",
                form: "inverseReverseTotalShoulderReplacement",
                formData: {
                  "primaryOrRevision": "",
                  "size": "",
                  "titaniumHead": false,
                  "glenoidDeficiency": "",
                  "walchClassification": "",
                  "glenoidRetroversion": "",
                  "boneGraft": "",
                  "lateralOffset": "",
                  "eccentric": "",
                  "type": "",
                  "type2": "",
                  "spacerPolySize": "",
                  "metalSpacerTrays": "",
                  "type3": "",
                  "stemDetails": "",
                  "size2": "",
                  "humeralCup": "",
                  "humeralOfffset": "",
                  "humeralInclination": "",
                  "humeralVersion": "",
                  "humeralVersion2": "",
                  "manufacturer": "",
                  "latTransfer": "",
                  "planning3d": "",
                  "osAcromiale": "",
                  "cerclage": "",
                }
              }  
          case "superiorCapsuleReconstruction":
              return {
                category: "operationsAndImplants",
                form: "superiorCapsuleReconstruction",
                formData: {
                  "primaryOrRevision": "",
                  "open": false,
                  "glenoidSideGraftFixation": "",
                  "tieSuturesFromTheGlenoidAnchorToTheSupraspinatusTendon": false,
                  "humeralSideGraftFixation": "",
                  "graftUsed": "",
                  "armPositionForMeasuringPositioningAndSecuringGraft": "",
                  "posteriorMarginConvergencePerformed": "",
                  "anteriorMarginConvergencePerformed": "",
                  "procedures": "",
                  "glenoidSutureTak": "",
                  "numberOfSutureTaksUsed": "",
                  "glenoidSwiveLock": "",
                  "sutureUsedWithSwiveLock": "",
                  "numberOfSwiveLocksUsed": "",
                  "glenoidPushLock": "",
                  "sutureUsedWithPushLock": "",
                  "numberOfPushLocksUsed": "",
                  "typeOfKnotlessAnchor": "",
                  "materialKnotlessAnchor": "",
                  "sutureUsedWithKnotlessAnchor": "",
                  "numberOfKnotlessAnchorsUsed": "",
                  "typeOfSutureAnchor": "",
                  "materialSutureAnchor": "",
                  "numberOfSutureAnchorsUsed": "",
                  "typeOfKnotlessAnchor2": "",
                  "materialKnotlessAnchor2": "",
                  "accessorySutureUsed": "",
                  "numberOfKnotlessAnchorsUsed2": "",
                  "superiorLabrumManagement": "",
                }
              }  
          case "clavicleFracture":
              return {
                category: "operationsAndImplants",
                form: "clavicleFracture",
                formData: {
                  "primaryOrRevision": "",
                  "procedures": "",
                  "manufacturer": "",
                }
              }  
          case "greaterTuberosityFracture":
              return {
                category: "operationsAndImplants",
                form: "greaterTuberosityFracture",
                formData: {
                  "primaryOrRevision": "",
                  "boneGraft": "",
                  "manufacturer": "",
                  "procedures": "",
                }
              }  
          case "proximalHumerusFracture":
              return {
                category: "operationsAndImplants",
                form: "proximalHumerusFracture",
                formData: {
                  "primaryOrRevision": "",
                  "procedures": "",
                  "boneGraft": "",
                  "manufacturer": "",
                }
              }  
          case "humeralShaftFracture":
              return {
                category: "operationsAndImplants",
                form: "humeralShaftFracture",
                formData: {
                  "procedures": "",
                }
              }  
          case "glenoidFracture":
              return {
                category: "operationsAndImplants",
                form: "glenoidFracture",
                formData: {
                  "primaryOrRevision": "",
                  "procedures": "",
                  "boneGraft": "",
                  "manufacturer": "",
                }
              }  
          case "fusion":
              return {
                category: "operationsAndImplants",
                form: "fusion",
                formData: {
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "boneGraft": "",
                  "type": "",
                }
              }  
          case "prostalac":
              return {
                category: "operationsAndImplants",
                form: "prostalac",
                formData: {
                }
              }  
          default: return
        }
      default: return
    }
}

export const camelCaseToReadable = (str) => {
  return str
    .replace(/([A-Z])/g, ' $1') // Add space before each uppercase letter
    .replace(/^./, (match) => match.toUpperCase()); // Capitalize the first letter
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};