import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BASE_URL, studyCamelCaseToSpacedCapitalizedMap, studySpacedCapitalizedToCamelCaseMap } from '../../utils/constants';
import { Table, Button, Form, Accordion } from 'react-bootstrap'
import { Oval } from "react-loader-spinner"
import Modal from 'react-bootstrap/Modal'
import { useNavigate,} from 'react-router-dom';
import { FaChartLine, FaPlus } from "react-icons/fa6";
import { Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart, LabelList } from 'recharts';


import PatientInformationSurgicalTimeAndPersonnel from '../../components/ArthrexCatalog/General/PatientInformationSurgicalTimeAndPersonnel/PatientInformationSurgicalTimeAndPersonnel'
import HandDominance from '../../components/ArthrexCatalog/General/HandDominance/HandDominance';
import Diagnosis from '../../components/ArthrexCatalog/General/Diagnosis/Diagnosis';
import Operations from '../../components/ArthrexCatalog/General/Operations/Operations';

import OperationsLabrumAndInstability from '../../components/ArthrexCatalog/General/OperationsLabrumAndInstability/OperationsLabrumAndInstability';
import Implants from '../../components/ArthrexCatalog/General/Implants/Implants';
import SurgicalDressing from '../../components/ArthrexCatalog/General/SurgicalDressing/SurgicalDressing';
import Grafts from '../../components/ArthrexCatalog/General/Grafts/Grafts';
import OperationsAndImplants from '../../components/ArthrexCatalog/ShoulderArthroplasty/OperationsAndImplants/OperationsAndImplants';
import PostOperativeManagement from '../../components/ArthrexCatalog/ShoulderArthroplasty/PostOperativeManagement/PostOperativeManagement';

import { camelCaseToReadable, getCatalogEntryData, getRandomColor } from '../../utils/fuctions';

import "./Analysis.scss"

// Custom label function
const CustomLabel = (props) => {
  const { x, y, value, height, style, stage, patientGroups, dataKey } = props;

  // Determine position and offset dynamically
  const midY = height / 4;
  const position = y < midY ? 'bottom' : 'top';
  let hasOverlap = false;
  let dy = position === 'top' ? -10 : 20;
  let newDy = dy;

  // Adjust dy based on the proximity of other labels
  const proximityThreshold = 50; // Adjust as needed for minimum distance

  // Find overlapping labels at the same stage across all groups
  const overlappingLabels = [];

  if (Array.isArray(patientGroups)) {
    patientGroups.forEach((group) => {
      const groupData = group.metrics[dataKey];
      const stageData = groupData.find(item => item.stage === stage);
      if (stageData) {
        const labelX = x;
        const labelY = stageData.indexScore; // Change this based on the correct field for Y value

        if (Math.abs(labelX - x) < proximityThreshold && Math.abs(labelY - y) < proximityThreshold) {
          overlappingLabels.push({ x: labelX, y: labelY });
        }
      }
    });
  }

  // Adjust dy to avoid overlap
  if (overlappingLabels.length > 1) {
    hasOverlap = true
    newDy = position === 'top' ? dy - proximityThreshold : dy + proximityThreshold;
  }

  return (
    <text
      x={x}
      y={y}
      dy={hasOverlap? newDy : dy} // Ensure dy is a string
      fill={style.fill}
      textAnchor="middle"
    >
      {value}
    </text>
  );
};

// Helper function to wrap LabelList with custom height and data
const renderCustomLabel = (height, style, stage, patientGroups, dataKey) => (props) => (
  <CustomLabel
    {...props}
    height={height}
    style={style}
    stage={stage}
    patientGroups={patientGroups}
    dataKey={dataKey}
  />
);

const Analysis = ({ user, logUserOut }) => {
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [study, setStudy] = useState("");
  const [numberOfGroups, setNumberOfGroups] = useState(0)
  const [patientGroups, setPatientGroups] = useState([])
  const [groupName, setGroupName] = useState(`Group ${numberOfGroups + 1}`);
  const [cases, setCases] = useState(0);
  const [editingGroupIndex, setEditingGroupIndex] = useState(null);
  const [catalogEntries, setCatalogEntries] = useState([])
  const [sorting, setSorting] = useState({ field: 'alertDate', ascending: true })
  const navigate = useNavigate();
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  }

  useEffect(() => {
    const resetCatalogEntries = async () => {
      setCatalogEntries([])
    }
    if(editingGroupIndex === null) {
      resetCatalogEntries()
    }
  }, [study])
  

  useEffect(() => {
    const getAnalysisGroupCasesCount = async () => {
      try {
        const body = {
          study: study,
          catalogEntries: catalogEntries
        }
        const response = await axios.post(`${BASE_URL}/api/v1/analysis/cases-count`, body, config)
        setCases(response.data.casesCount)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    getAnalysisGroupCasesCount()
  }, [catalogEntries])

  useEffect(() => {
    const resetGroupCreation = async () => {
      setCatalogEntries([])
      setGroupName(`Group ${numberOfGroups + 1}`)
      setStudy("")
      setCases(0)
    }
    if(editingGroupIndex === null) {
      resetGroupCreation()
    }
  }, [showModal])

  const editPatientGroupHandler = async (groupIndex) => {
    const groupToEdit = patientGroups[groupIndex];

    setEditingGroupIndex(groupIndex); // Set this before showing the modal
    setStudy(studySpacedCapitalizedToCamelCaseMap[groupToEdit.study]);
    setGroupName(groupToEdit.groupName);
    setCatalogEntries(groupToEdit.catalogEntries);
    setCases(groupToEdit.cases);
    setShowModal(true)
  };

  const savePatientGroupHandler = async () => {
    const { numberOfCases, analysisMetrics } = await getAnalysisGroupMetrics();
    const color = getRandomColor();
    const newGroup = {
      groupName: groupName,
      catalogEntries: catalogEntries,
      study: studyCamelCaseToSpacedCapitalizedMap[study],
      cases: numberOfCases,
      metrics: analysisMetrics,
      color: color
    };

    setPatientGroups(prevState => {
      if (editingGroupIndex !== null) {
        const updatedGroups = [...prevState];
        updatedGroups[editingGroupIndex] = newGroup;
        return updatedGroups;
      }
      return [...prevState, newGroup];
    });

    setNumberOfGroups(prevState => editingGroupIndex !== null ? prevState : prevState + 1);
    setShowModal(false);
    setEditingGroupIndex(null);
  };

  const getAnalysisGroupMetrics = async () => {
    try {
      const body = {
        study: study,
        catalogEntries: catalogEntries
      }
      const response = await axios.post(`${BASE_URL}/api/v1/analysis/analysis-metrics`, body, config)
      return response.data
    }
    catch (error) {
      console.log(error)
      const message = error.response && error.response.data.message ? error.response.data.message : error.message
      if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
        logUserOut()
      }
    }
  }
  
  const addCatalogEntry = (category, form) => {
    const entryAlreadyExists = catalogEntries.some(categoryEntry => categoryEntry.category === category && categoryEntry.form === form);
    if (!entryAlreadyExists) {
      setCatalogEntries([...catalogEntries, getCatalogEntryData(category, form)])
    }
  }

  const handleCatalogEntryFormDataUpdate = (arrayIndex, key, value) => {
    let newCatalogEntries = [...catalogEntries]
    newCatalogEntries[arrayIndex].formData[key] = value
    setCatalogEntries(newCatalogEntries)
  }

  const handleCatalogEntryRemoval = (arrayIndex) => {
    setCatalogEntries(catalogEntries.filter((catalogEntry, index) => index !== arrayIndex))
  }

  const renderCatalog = () => {
    switch(study) {
      case "shoulderArthroscopy": {
        return (
          <>
            <Accordion.Item eventKey="3" className="diagnosis">
              <Accordion.Header>Diagnosis</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("diagnosis", "rotatorCuff")}>Rotator Cuff</button>
                <button onClick={() => addCatalogEntry("diagnosis", "subacromialImpingement")}>Subacromial Impingement</button>
                <button onClick={() => addCatalogEntry("diagnosis", "labralInjuryAndInstability")}>Labral injury and instability</button>
                <button onClick={() => addCatalogEntry("diagnosis", "biceps")}>Biceps</button>
                <button onClick={() => addCatalogEntry("diagnosis", "acJoint")}>AC joint</button>
                <button onClick={() => addCatalogEntry("diagnosis", "osAcromiale")}>Os acriomiale</button>
                <button onClick={() => addCatalogEntry("diagnosis", "glenohumeralArthritis")}>Glenohumeral arthritis</button>
                <button onClick={() => addCatalogEntry("diagnosis", "articularCartilageOsteochondralLesion")}>Articular cartilage/osteochondral lesion</button>
                <button onClick={() => addCatalogEntry("diagnosis", "otherIntraArticular")}>Other intra-articular</button>
                <button onClick={() => addCatalogEntry("diagnosis", "fracture")}>Fracture</button>
                <button onClick={() => addCatalogEntry("diagnosis", "miscellaneous")}>Miscellaneous</button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className="operations">
              <Accordion.Header>Operations</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("operations", "rotatorCuffCompleteTear")}>Rotator cuff (complete tear)</button>
                <button onClick={() => addCatalogEntry("operations", "rotatorCuffPartialTear")}>Rotator cuff (partial tear)</button>
                <button onClick={() => addCatalogEntry("operations", "superiorCapsuleReconstruction")}>Superior Capsule Reconstruction (SCR)</button>
                <button onClick={() => addCatalogEntry("operations", "subscap")}>Subscap</button>
                <button onClick={() => addCatalogEntry("operations", "subAcromialDecompression")}>Subacromial decompression</button>
                <button onClick={() => addCatalogEntry("operations", "tendonTransfer")}>Tendon transfer</button>
                <button onClick={() => addCatalogEntry("operations", "biceps")}>Biceps</button>
                <button onClick={() => addCatalogEntry("operations", "coracoplasty")}>Coracoplasty</button>
                <button onClick={() => addCatalogEntry("operations", "distalClavicleExcision")}>Distal clavicle excision</button>
                <button onClick={() => addCatalogEntry("operations", "acJointStabilization")}>AC joint stabilization</button>
                <button onClick={() => addCatalogEntry("operations", "osAcromiale")}>Os acromiale</button>
                <button onClick={() => addCatalogEntry("operations", "osteotomy")}>Osteotomy</button>
                <button onClick={() => addCatalogEntry("operations", "cartilageTreatment")}>Cartilage treatment</button>
                <button onClick={() => addCatalogEntry("operations", "osteochondralTreatment")}>Osteochondral treatment</button>
                <button onClick={() => addCatalogEntry("operations", "otherIntraArticular")}>Other intra-articular</button>
                <button onClick={() => addCatalogEntry("operations", "fractureManagement")}>Fracture management (ORIF)</button>
                <button onClick={() => addCatalogEntry("operations", "miscellaneous")}>Miscellaneous</button>
              </Accordion.Body>

            </Accordion.Item>
            <Accordion.Item eventKey="5" className="operations-labrum-and-instability">
              <Accordion.Header>Operations - labrum & instability</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "slap")}>SLAP</button>
                <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "labralRepair")}>Labral repair</button>
                <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "capsulorrhaphy")}>Capsulorrhaphy</button>
                <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "haglRepair")}>HAGL repair</button>
                <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "alpsaRepair")}>ALPSA repair</button>
                <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "remplissage")}>Remplissage</button>
                <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "subscapularisAugmentation")}>Subscapularis augmentation</button>
                <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "glenoidBoneGraftProcedure")}>Glenoid bone graft procedure</button>
                <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "humeralBoneGraftProcedure")}>Humeral bone graft procedure</button>
                {/* <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "dynamicAnteriorStabilization")}>Dynamic Anterior Stabilization (DAS)</button> */}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6" className="grafts">
              <Accordion.Header>Grafts</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftAutografts")}>Bone graft - autografts</button>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftAllografts")}>Bone graft - allografts</button>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftSyntheticBoneVoidFillers")}>Bone graft - synthetic bone void fillers</button>
                <button onClick={() => addCatalogEntry("grafts", "cartilageGraftChondralGrafts")}>Cartilage graft - chondral grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "cartilageGraftOsteochondralGrafts")}>Cartilage graft - osteochondral grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "softTissueTendonGrafts")}>Soft tissue - tendon grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "softTissueAugmentationGrafts")}>Soft tissue - augmentation grafts</button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7" className="implants">
              <Accordion.Header>Implants</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("implants", "acJointStabilization")}>AC joint stabilization</button>
                <button onClick={() => addCatalogEntry("implants", "biceps")}>Biceps</button>
                <button onClick={() => addCatalogEntry("implants", "capsularRepairHaglAlpsaRemplissageAsa")}>Capsular repair HAGL/ALPSA/remplissage/ASA</button>
                <button onClick={() => addCatalogEntry("implants", "chondral")}>Chondral</button>
                <button onClick={() => addCatalogEntry("implants", "fractureManagement")}>Fracture management (ORIF)</button>
                <button onClick={() => addCatalogEntry("implants", "glenoidBoneGraftProcedure")}>Glenoid bone graft procedure</button>
                <button onClick={() => addCatalogEntry("implants", "labralRepair")}>Labral repair</button>
                <button onClick={() => addCatalogEntry("implants", "osAcromiale")}>Os acromiale</button>
                <button onClick={() => addCatalogEntry("implants", "osteotomy")}>Osteotomy</button>
                <button onClick={() => addCatalogEntry("implants", "pastaRepairKit")}>Pasta repair kit</button>
                <button onClick={() => addCatalogEntry("implants", "pecMajor")}>Pec major</button>
                <button onClick={() => addCatalogEntry("implants", "rotatorCuffImplantsLateralRow")}>Rotator cuff implants - lateral row (lateral half of greater tuberosity)</button>
                <button onClick={() => addCatalogEntry("implants", "rotatorCuffImplantsMedialRow")}>Rotator cuff implants - medial row (medial half of greater tuberosity)</button>
                <button onClick={() => addCatalogEntry("implants", "cuffMend")}>Cuff Mend</button>
                <button onClick={() => addCatalogEntry("implants", "slap")}>SLAP</button>
                <button onClick={() => addCatalogEntry("implants", "subscap")}>Subscap</button>
                <button onClick={() => addCatalogEntry("implants", "superiorCapsuleReconstructionGlenoid")}>Superior Capsule Reconstruction (SCR) - glenoid</button>
                <button onClick={() => addCatalogEntry("implants", "superiorCapsuleReconstructionHumeralSideLateralRow")}>Superior Capsule Reconstruction (SCR) - humeral side lateral Row</button>
                <button onClick={() => addCatalogEntry("implants", "superiorCapsuleReconstructionHumeralSideMedialRow")}>Superior Capsule Reconstruction (SCR) - humeral side medial Row</button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8" className="surgicalDressing">
              <Accordion.Header>Surgical Dressing</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("surgicalDressing")}>Sling/Brace application</button>
              </Accordion.Body>
            </Accordion.Item>
          </>
        )
      }
      case "shoulderArthroplasty": {
        return (
          <>
            <Accordion.Item eventKey="3" className="diagnosis">
              <Accordion.Header>Diagnosis</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("diagnosis", "arthritis")}>Arthritis</button>
                <button onClick={() => addCatalogEntry("diagnosis", "rotatorCuffArthropathy")}>Rotator Cuff Arthropathy</button>
                <button onClick={() => addCatalogEntry("diagnosis", "failedRotatorCuffRepair")}>Failed Rotator Cuff Repair</button>
                <button onClick={() => addCatalogEntry("diagnosis", "failedShoulderArthroplasty")}>Failed Shoulder Arthroplasty</button>
                <button onClick={() => addCatalogEntry("diagnosis", "clavicleFracture")}>Clavicle Fracture</button>
                <button onClick={() => addCatalogEntry("diagnosis", "humeralFracture")}>Humeral Fracture</button>
                <button onClick={() => addCatalogEntry("diagnosis", "scapulaFracture")}>Scapula Fracture</button>
                <button onClick={() => addCatalogEntry("diagnosis", "chronicRotatorCuffTear")}>Chronic Rotator Cuff Tear</button>
                <button onClick={() => addCatalogEntry("diagnosis", "avn")}>AVN</button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className="operations">
              <Accordion.Header>Operations/Implants</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "approachSupscapRepairAndApplicationBraces")}>Approach, Supscap Repair and Application/Braces</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "hemiarthroplasty")}>Hemiarthroplasty</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "humeralHeadResurfacing")}>Humeral Head Resurfacing</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "biologicTotalShoulder")}>Biologic Total Shoulder</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "totalShoulderReplacement")}>Total Shoulder Replacement</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "inverseReverseTotalShoulderReplacement")}>Inverse/Reverse Total Shoulder Replacement</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "superiorCapsuleReconstruction")}>Superior Capsule Reconstruction (SCR)</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "clavicleFracture")}>Clavicle Fracture</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "greaterTuberosityFracture")}>Greater Tuberosity Fracture</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "proximalHumerusFracture")}>Proximal Humerus Fracture</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "humeralShaftFracture")}>Humeral Shaft Fracture</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "glenoidFracture")}>Glenoid Fracture</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "fusion")}>Fusion</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "prostalac")}>Prostalac</button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" className="grafts">
              <Accordion.Header>Grafts</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftAutografts")}>Bone graft - autografts</button>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftAllografts")}>Bone graft - allografts</button>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftSyntheticBoneVoidFillers")}>Bone graft - synthetic bone void fillers</button>
                <button onClick={() => addCatalogEntry("grafts", "cartilageGraftChondralGrafts")}>Cartilage graft - chondral grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "cartilageGraftOsteochondralGrafts")}>Cartilage graft - osteochondral grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "softTissueMeniscalAllograftFasciaLata")}>Soft Tissue - Meniscal Allograft, Fascia Lata, etc</button>
                <button onClick={() => addCatalogEntry("grafts", "softTissueAugmentationGrafts")}>Soft tissue - augmentation grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "softTissueAdiposeTissueGraft")}>Soft Tissue - Adipose Tissue Graft (Autograft)</button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6" className="postOperativeManagement">
              <Accordion.Header>Post Operative Management</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("postOperativeManagement")}>Post-Op Path</button>
              </Accordion.Body>
            </Accordion.Item>
          </>
        )
      }
      case "elbow": {
        return (
          <>
            <Accordion.Item eventKey="3" className="diagnosis">
              <Accordion.Header>Diagnosis</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("diagnosis", "tendon")}>Tendon</button>
                <button onClick={() => addCatalogEntry("diagnosis", "elbowLigamentInstability")}>Ligament/Instability</button>
                <button onClick={() => addCatalogEntry("diagnosis", "elbowOsteochondralLesion")}>Osteochondral Lesion</button>
                <button onClick={() => addCatalogEntry("diagnosis", "elbowOsteoarthritis")}>Osteoarthritis</button>
                <button onClick={() => addCatalogEntry("diagnosis", "elbowOtherInflammatoryArthritis")}>Other Inflammatory Arthritis</button>
                <button onClick={() => addCatalogEntry("diagnosis", "elbowLooseBody")}>Loose Body(s)</button>
                <button onClick={() => addCatalogEntry("diagnosis", "elbowCapsuleSynovium")}>Capsule/Synovium</button>
                <button onClick={() => addCatalogEntry("diagnosis", "elbowNerve")}>Nerve</button>
                <button onClick={() => addCatalogEntry("diagnosis", "elbowFracture")}>Fracture</button>
                <button onClick={() => addCatalogEntry("diagnosis", "olecranonSpur")}>Olecranon Spur</button>
                <button onClick={() => addCatalogEntry("diagnosis", "elbowOther")}>Other</button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className="operations">
              <Accordion.Header>Operations</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("operations", "elbowCaseDetails")}>Case Details</button>
                <button onClick={() => addCatalogEntry("operations", "elbowTendonProcedures")}>Tendon Procedures</button>
                <button onClick={() => addCatalogEntry("operations", "elbowCollateralLigaments")}>Collateral Ligaments</button>
                <button onClick={() => addCatalogEntry("operations", "elbowCartilageTreatment")}>Cartilage Treatment</button>
                <button onClick={() => addCatalogEntry("operations", "elbowOsteochondralTreatment")}>Osteochondral Treatment</button>
                <button onClick={() => addCatalogEntry("operations", "elbowOsteophyteDebridementExcision")}>Osteophyte Debridement/Excision</button>
                <button onClick={() => addCatalogEntry("operations", "elbowRemovalLooseBody")}>Removal of Loose Body(s)</button>
                <button onClick={() => addCatalogEntry("operations", "elbowCapsuleSynovium")}>Capsule/Synovium</button>
                <button onClick={() => addCatalogEntry("operations", "elbowNerve")}>Nerve</button>
                <button onClick={() => addCatalogEntry("operations", "elobwOrif")}>ORIF</button>
                <button onClick={() => addCatalogEntry("operations", "radialHeadExcision")}>Radial Head Excision</button>
                <button onClick={() => addCatalogEntry("operations", "radialHeadImplant")}>Radial Head Implant</button>
                <button onClick={() => addCatalogEntry("operations", "totalElbowArthroplasty")}>Total Elbow Arthroplasty</button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" className="grafts">
              <Accordion.Header>Grafts</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftAutografts")}>Bone graft - Autografts</button>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftAllografts")}>Bone graft - allografts</button>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftFreshAllografts")}>Bone graft - fresh allografts</button>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftSyntheticBoneVoidFillers")}>Bone graft - synthetic bone void fillers</button>
                <button onClick={() => addCatalogEntry("grafts", "cartilageGraftChondralGrafts")}>Cartilage graft - chondral grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "cartilageGraftOsteochondralGrafts")}>Cartilage graft - osteochondral grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "elbowSoftTissueTendonGrafts")}>Soft tissue - tendon grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "softTissueAugmentationGrafts")}>Soft tissue - augmentation grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "softTissueAdiposeTissueGraft")}>Soft tissue - adipose tissue graft (autograft)</button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6" className="implants">
              <Accordion.Header>Implants</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("implants", "elbowTendonProcedures")}>Tendon Procedures</button>
                <button onClick={() => addCatalogEntry("implants", "elbowCollateralLigaments")}>Collateral Ligaments</button>
                <button onClick={() => addCatalogEntry("implants", "elbowCartilageTreatment")}>Cartilage Treatment</button>
                <button onClick={() => addCatalogEntry("implants", "elbowOsteochondralTreatment")}>Osteochondral Treatment</button>
                <button onClick={() => addCatalogEntry("implants", "elbowOrif")}>ORIF</button>
                <button onClick={() => addCatalogEntry("implants", "elbowRadialHeadImplant")}>Radial Head Implant</button>
                <button onClick={() => addCatalogEntry("implants", "elbowTotalElbowArthroplasty")}>Total Elbow Arthroplasty</button>
              </Accordion.Body>
            </Accordion.Item>
          </>
        )
      }
      default: return
    }
  }

  return (
    <div className="analysis">
      {loading ? (<Oval
          height={80}
          width={80}
          color="#4caeff"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#4caeff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        ) : (
        <>
          <Modal className={"analysis-modal"} show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                <div>New Patient Group</div>
                <div>{cases} cases</div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={e => {e.preventDefault(); savePatientGroupHandler()}}>
                <Form.Group className='group-study'>
                  <Form.Label><h4>Study</h4></Form.Label>
                  <Form.Select
                        value={study}
                        onChange={(e) => {setStudy(e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="shoulderArthroplasty">Shoulder Arthroplasty</option>
                        <option value="elbow">Elbow</option>
                        <option value="shoulderArthroscopy">Shoulder Arthroscopy</option>
                      </Form.Select>
                </Form.Group>
                <Form.Group className='group-name'>
                  <Form.Label><h4>Group Name</h4></Form.Label>
                  <Form.Control value={groupName} type="text" onChange={(e) => setGroupName(e.target.value)}/>
                </Form.Group>
              </Form>
              
              <div className='divider'></div>

              {study !== "" ? 
                (
                  <div className="analysis-group-metadata">
                    <div className="analysis-group-metadata-overview">
                      <h4>Catalog</h4>
                      <Accordion>
                        <Accordion.Item eventKey="0" className="patient-information-and-surgical-time-and-personnel">
                          <Accordion.Header>Patient information and surgical time/personnel</Accordion.Header>
                          <Accordion.Body>
                            <button onClick={() => addCatalogEntry("patientInformationSurgicalTimeAndPersonnel", "patientInformation")}>Patient information</button>
                            <button onClick={() => addCatalogEntry("patientInformationSurgicalTimeAndPersonnel", "surgicalTimeAndPersonnel")}>Surgical time and personnel</button>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className="hand-dominance">
                          <Accordion.Header>Hand dominance</Accordion.Header>
                          <Accordion.Body>
                            <button onClick={() => addCatalogEntry("handDominance")}>Hand dominance</button>
                          </Accordion.Body>
                        </Accordion.Item>
                        {
                          renderCatalog()
                        }
                      </Accordion>
                    </div>
                    <div className="analysis-group-metadata-details">
                      <div className="analysis-group-metadata-details-header">
                        <h4>Forms</h4>
                      </div>
                      {catalogEntries && catalogEntries.map((catalogEntry, index) => {
                        switch(catalogEntry.category) {
                          case "patientInformationSurgicalTimeAndPersonnel":
                            return <PatientInformationSurgicalTimeAndPersonnel key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval}/>
                          case "handDominance":
                            return <HandDominance key={index} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval} study={study}/>
                          case "diagnosis":
                            return <Diagnosis key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval}/>
                          case "operations":
                            return <Operations key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval}/>
                          case "operationsLabrumAndInstability":
                            return <OperationsLabrumAndInstability key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval}/>
                          case "implants":
                            return <Implants key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval}/>
                          case "surgicalDressing":
                            return <SurgicalDressing key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval}/>
                          case "postOperativeManagement":
                            return <PostOperativeManagement key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval}/>
                          case "grafts":
                            return <Grafts key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval} study={study}/>
                          case "operationsAndImplants":
                            return <OperationsAndImplants key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval}/>
                          default: return null
                        }
                      })}
                    </div>
                  </div>   
                ) : null
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Cancel
              </Button>
              <Button variant="success" disabled={study === ""? true : false} onClick={() => savePatientGroupHandler()}>
                Save Patient Group
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="analysis-header">
            <h2 className="logo"><FaChartLine /> Analysis</h2> 
            <Button variant="success" onClick={() => {setEditingGroupIndex(null); setShowModal(true)}}>
              <FaPlus /> Create group
            </Button>
          </div>
          <div className='analysis-table'>
            <Table striped bordered={false} hover>
              <thead>
                <tr>
                  <th className='group-name'>Group Name</th>
                  <th className='study'>Study</th>
                  <th className='cases'>Cases</th>
                  <th className='filters'>Filters</th>
                  <th className='actions'></th>
                </tr>
              </thead>
              <tbody>
                {patientGroups.map((patientGroup, index) => (
                  <tr key={patientGroup.groupName}>
                    <td><b>{patientGroup.groupName}</b></td>
                    <td>{patientGroup.study}</td>
                    <td>{patientGroup.cases}</td>
                    <td>
                      {patientGroup.catalogEntries.map((catalogEntry, catalogEntryIndex) => (
                         <div key={catalogEntryIndex}>
                         <b>{camelCaseToReadable(catalogEntry.category)}</b> - {camelCaseToReadable(catalogEntry.form)}
                         {catalogEntryIndex < catalogEntries.length - 1 && <br />}
                       </div>
                      ))}
                    </td>
                    <td>
                      <div className='action-buttons'>
                        <Button variant="primary" onClick={() => {setEditingGroupIndex(index); editPatientGroupHandler(index)}}>Edit</Button>{' '}
                        <Button variant="danger" onClick={() => {setPatientGroups(prevState => prevState.filter((_, i) => i !== index)); setNumberOfGroups(prevState => prevState - 1)}}>Remove</Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className='analysis-graphs'>
            {patientGroups.length > 0 &&
              <ResponsiveContainer width="100%" height="50%">
                <ComposedChart
                  width={600}
                  height={400}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 50,
                  }}
                  overflow="visible" 
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="stage" 
                    angle={45} 
                    textAnchor="start"
                    interval={0}
                    allowDuplicatedCategory={false}
                    dy={10}
                  />
                  <YAxis 
                    label={{ value: 'VAS Score', angle: -90, offset:20, position:'insideBottomLeft'}} 
                    domain={[0, 10]}                
                    tickCount={10}  
                  />
                  <Tooltip />
                  <Legend
                    layout="horizontal" 
                    verticalAlign="top" 
                    align="center"/>
                  {
                    patientGroups.map((patientGroup, index) => {
                      return (
                        <Line 
                          type="monotone" 
                          key={`line-${index}`}
                          data={patientGroup.metrics.vasData} 
                          dataKey="score" 
                          stroke={patientGroup.color}
                          fill={patientGroup.color} 
                          strokeWidth={2} 
                          activeDot={{ r: 8 }} 
                          name={patientGroup.groupName + ` (n=${patientGroup.cases})`}
                        >
                          <LabelList
                            key={`label-${index}`}
                            dataKey="score"
                            content={renderCustomLabel(400, { fill: patientGroup.color }, patientGroup.metrics.vasData[index]?.stage, patientGroups, 'vasData')}
                          />
                        </Line>
                      )
                    })
                  }
                </ComposedChart>
              </ResponsiveContainer>
            }
            {patientGroups.length > 0 &&
              <ResponsiveContainer width="100%" height="50%">
                <ComposedChart
                  width={600}
                  height={400}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 50,
                  }}
                  overflow="visible"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="stage" 
                    angle={45} 
                    textAnchor="start"
                    interval={0}
                    dy={10}
                    allowDuplicatedCategory={false}
                  />
                  <YAxis 
                    label={{ value: 'Sane Score', angle: -90, offset:10, position:'insideBottomLeft'}} 
                    domain={[0, 100]}                
                    tickCount={10}
                    // dx={10}
                  />
                  <Tooltip />
                  <Legend
                    layout="horizontal" 
                    verticalAlign="top" 
                    align="center"/>
                  {
                    patientGroups.map((patientGroup, index) => {
                      return (
                        <Line 
                          type="monotone" 
                          key={`line-${index}`}
                          data={patientGroup.metrics.saneData} 
                          dataKey="score" 
                          stroke={patientGroup.color} 
                          fill={patientGroup.color} 
                          strokeWidth={2} 
                          activeDot={{ r: 8 }} 
                          name={patientGroup.groupName + ` (n=${patientGroup.cases})`}
                        >
                          <LabelList
                            key={`label-${index}`}
                            dataKey="score"
                            content={renderCustomLabel(400, { fill: patientGroup.color }, patientGroup.metrics.saneData[index]?.stage, patientGroups, 'saneData')}
                          />
                        </Line>
                      )
                    })
                  }
                </ComposedChart>
              </ResponsiveContainer>
            }
            {patientGroups.length > 0 && patientGroups.study === "elbow" &&
              <ResponsiveContainer width="100%" height="50%">
                <ComposedChart
                  width={600}
                  height={400}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 50,
                  }}
                  overflow="visible"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="stage" 
                    angle={45} 
                    textAnchor="start"
                    interval={0}
                    dy={10}
                    allowDuplicatedCategory={false}
                  />
                  <YAxis 
                    label={{ value: 'ASES Elbow Score', angle: -90, offset:10, position:'insideBottomLeft'}} 
                    domain={[0, 36]}                
                    tickCount={6}
                    // dx={10}
                  />
                  <Tooltip />
                  <Legend
                    layout="horizontal" 
                    verticalAlign="top" 
                    align="center"/>
                  {
                    patientGroups.map((patientGroup, index) => {
                      return (
                        <Line 
                          type="monotone" 
                          key={`line-${index}`}
                          data={patientGroup.metrics.asesElbowData} 
                          dataKey="score" 
                          stroke={patientGroup.color} 
                          fill={patientGroup.color} 
                          strokeWidth={2} 
                          activeDot={{ r: 8 }} 
                          name={patientGroup.groupName + ` (n=${patientGroup.cases})`}
                        >
                          <LabelList
                            key={`label-${index}`}
                            dataKey="score"
                            content={renderCustomLabel(400, { fill: patientGroup.color }, patientGroup.metrics.asesElbowData[index]?.stage, patientGroups, 'asesElbowData')}
                          />
                        </Line>
                      )
                    })
                  }
                </ComposedChart>
              </ResponsiveContainer>
            }
            {patientGroups.length > 0 && patientGroups.study !== "elbow" &&
              <ResponsiveContainer width="100%" height="50%">
                <ComposedChart
                  width={600}
                  height={400}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 50,
                  }}
                  overflow="visible"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="stage" 
                    angle={45} 
                    textAnchor="start"
                    interval={0}
                    dy={10}
                    allowDuplicatedCategory={false}
                  />
                  <YAxis 
                    label={{ value: patientGroups[0].study === "elbow"? 'ASES Elbow Score' : 'ASES Function Score', angle: -90, offset:10, position:'insideBottomLeft'}} 
                    domain={patientGroups[0].study === "elbow"? [0, 36] : [0, 30]}
                    tickCount={6}
                    // dx={10}
                  />
                  <Tooltip />
                  <Legend
                    layout="horizontal" 
                    verticalAlign="top" 
                    align="center"/>
                  {
                    patientGroups.map((patientGroup, index) => {
                      return (
                        <Line 
                          type="monotone" 
                          key={`line-${index}`}
                          data={patientGroup.study === "elbow"? patientGroup.metrics.asesElbowData : patientGroup.metrics.asesFunctionData} 
                          dataKey={patientGroup.study === "elbow"? "score" : "functionScore"}
                          stroke={patientGroup.color} 
                          fill={patientGroup.color} 
                          strokeWidth={2} 
                          activeDot={{ r: 8 }} 
                          name={patientGroup.groupName + ` (n=${patientGroup.cases})`}
                        >
                          <LabelList
                            key={`label-${index}`}
                            dataKey="functionScore"
                            content={renderCustomLabel(400, { fill: patientGroup.color }, patientGroup.study === "elbow"? patientGroup.metrics.asesElbowData[index]?.stage : patientGroup.metrics.asesFunctionData[index]?.stage, patientGroups, patientGroup.study === "elbow"? "asesElbowData" : "asesFunctionData")}
                          />
                        </Line>
                      )
                    })
                  }
                </ComposedChart>
              </ResponsiveContainer>
            }
            {patientGroups.length > 0 && patientGroups[0].study !== "elbow" &&
              <ResponsiveContainer width="100%" height="50%">
                <ComposedChart
                  width={600}
                  height={400}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 50,
                  }}
                  overflow="visible"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="stage" 
                    angle={45} 
                    textAnchor="start"
                    interval={0}
                    dy={10}
                    allowDuplicatedCategory={false}
                  />
                  <YAxis 
                    label={{ value: 'ASES Index Score', angle: -90, offset:10, position:'insideBottomLeft'}} 
                    domain={[0, 100]}                
                    tickCount={10}
                    // dx={10}
                  />
                  <Tooltip />
                  <Legend
                    layout="horizontal" 
                    verticalAlign="top" 
                    align="center"/>
                  {
                    patientGroups.map((patientGroup, index) => {
                      return (
                        <Line 
                          type="monotone" 
                          key={`line-${index}`}
                          data={patientGroup.metrics.asesIndexData} 
                          dataKey="indexScore" 
                          stroke={patientGroup.color} 
                          fill={patientGroup.color} 
                          strokeWidth={2} 
                          activeDot={{ r: 8 }} 
                          name={patientGroup.groupName + ` (n=${patientGroup.cases})`}
                        >
                          <LabelList
                            key={`label-${index}`}
                            dataKey="indexScore"
                            content={renderCustomLabel(400, { fill: patientGroup.color }, patientGroup.metrics.asesIndexData[index]?.stage, patientGroups, 'asesIndexData')}
                          />
                        </Line>
                      )
                    })
                  }
                </ComposedChart>
              </ResponsiveContainer>
            }
          </div>
        </>)}    
    </div>
  )
}

export default Analysis